import React from 'react'
import { useIntl } from 'react-intl'
import { Modal, Button, Divider } from 'antd'
import { GoogleOutlined, WindowsFilled } from '@ant-design/icons'

import IntlMessages from '../../util/IntlMessages'

interface ISignupWithAnotherPlatformModal {
  showModal: boolean
  domainPlatform: string
  connectWithPlatform: (e) => void
  handleSignupWithMail: () => void
  handleCloseSignupWithAnotherPlatformModal: () => void
}

const SignupWithAnotherPlatformModal= ({
  showModal,
  domainPlatform,
  connectWithPlatform,
  handleSignupWithMail,
  handleCloseSignupWithAnotherPlatformModal,
}: ISignupWithAnotherPlatformModal): React.ReactElement => {
  const { formatMessage } = useIntl()

  let platform
  let importUsersPlatform
  if (domainPlatform === 'google') {
    platform = 'Google Workspace'
    importUsersPlatform = 'Google Workspace'
  }
  if (domainPlatform === 'microsoft') {
    platform = 'Microsoft Office 365'
    importUsersPlatform = 'Microsoft Active Directory'
  }



  return (
    <Modal
      title={formatMessage({ id: 'components.signupWithAnotherPlatform.title'}, { platform })}
      visible={showModal}
      onCancel={handleCloseSignupWithAnotherPlatformModal}
      footer="" //Need to remove default buttons
      width={560}
    >
      <p><IntlMessages id="components.signupWithAnotherPlatform.info" values={{
        importUsersPlatform,
        platform,
        // eslint-disable-next-line
        helpDesk: (...chunks) => (
          <a href="https://vacationtracker.crisp.help/en/" target="_blank" rel="noopener noreferrer">
            {chunks}
          </a>
        ),
      }} /></p>
      <p>
        <Button type="primary" block size="large"
          icon={domainPlatform === 'microsoft' ? <WindowsFilled /> : <GoogleOutlined />}
          onClick={() => {
            handleCloseSignupWithAnotherPlatformModal()
            connectWithPlatform(domainPlatform)
          }}
        >
          <IntlMessages id="components.signupWithAnotherPlatform.connectWith" values={{ platform }} />
        </Button>
      </p>

      <Divider plain><IntlMessages id="app.or"/></Divider>

      <Button key="cancel" type="link" data-qa='go-to-email-signup' block onClick={handleSignupWithMail}>
        <IntlMessages id="connect.signUp" />
      </Button>
    </Modal>
  )
}

export default SignupWithAnotherPlatformModal
