import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Alert, Breadcrumb } from 'antd'
import { API, graphqlOperation } from 'aws-amplify'
import { orderBy } from 'lodash'
import { getLocations } from '../../../../graphql/custom-queries'
import { notificationStore } from '../../../../context/notificationsContext/store'

import { selectAuthCompanySlice } from '../../../../store/auth-company-slice'
import { useAppSelector } from '../../../../store/hooks'

import IntlMessages from '../../../../util/IntlMessages'
import LocationCard from '../../../../components/location-card'
import CircularProgress from '../../../../components/circular-progress'
import { NUMBER_OF_LOCATIONS_LIMIT } from '@vacationtracker/shared/data/app-parameters'

import { IGetLocationsData, IGetLocations } from '../../../../types/locations'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'

const LocationsPage = () => {
  const { actionNotifications } = useContext(notificationStore)
  const { authCompany } = useAppSelector(selectAuthCompanySlice)

  const [isLoading, setIsLoading] = useState(true)
  const [defaultLocationExists] = useState(false)
  const [showRenameWarning, setShowRenameWarning] = useState(false)
  const [locationList, setLocationList] = useState<IGetLocations[]>([])

  useEffect(() => {
    fetchLocationList()
  }, [])

  useEffect(() => {
    fetchLocationList()
  }, [actionNotifications])

  const fetchLocationList = async () => {
    try {
      const response = await API.graphql(graphqlOperation(getLocations)) as IGetLocationsData

      setLocationList(orderBy(response.data.getLocationList, [l => l.isDefault,'name'], ['desc', 'asc']))
      setIsLoading(false)
    } catch (err) { console.log('error fetching location list', err) }
  }

  useEffect(() => {
    const shouldRename = locationList.find(location => location.name.includes('<Autodetected for Departments'))
    if (shouldRename) {
      setShowRenameWarning(true)
    }
  }, [locationList])

  return (
    <div className='main-content'>
      <div className="main-content-header">
        <div className="main-content-header-title">
          <span><IntlMessages id="app.locations" /></span>
        </div>
        <div className="main-content-header-breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/app/dashboard"><IntlMessages id="sidebar.dashboard" /></Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item><IntlMessages id="sidebar.settings" /></Breadcrumb.Item>
            <Breadcrumb.Item><IntlMessages id="app.locations" /></Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className="main-content-body">
        <div className="locations-body">
          <div className="btn-actions">
            <Link to="/app/settings/locations/create" className="ant-btn ant-btn-default"><IntlMessages id="locations.createNewLocation" /></Link>
          </div>
          {isLoading ? <CircularProgress /> :
            <div>
              {showRenameWarning &&
                <Alert
                  key="warning"
                  message={<IntlMessages id="locations.pleaseRenameLocationName" />}
                  description={<IntlMessages id="locations.weAutomaticallyCreatedLocations" />}
                  type="warning"
                  showIcon
                  style={{ marginBottom: '20px' }}
                />
              }
              {locationList.map((location, index) => 
                <LocationCard
                  key={location.id}
                  isDefault={location.isDefault}
                  quickTourLocation={defaultLocationExists ? location.isDefault : (index === 0)} // If there is no default location, use the first one for the quick tour
                  id={location.id}
                  title={location.name}
                  users={location.users}
                />
              )}
              {authCompany?.plan === SubscriptionPlanEnum.core && locationList.length >= NUMBER_OF_LOCATIONS_LIMIT &&
                <Alert 
                  style={{ marginBottom: 20 }} 
                  message={
                    <IntlMessages id="locations.locationLimitReached" 
                      values={{
                        limitNumber: NUMBER_OF_LOCATIONS_LIMIT,
                        billingPage: (...chunks) => (<Link to="/app/settings/billing">{chunks}</Link>),
                      }} 
                    />} 
                  type="warning"
                />
              }
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default LocationsPage
