export interface ILocale {
  locale: LocaleEnum
  name: string
  icon: string
}

export interface IAvailableLanguages {
  [key: string]: ILocale
}

export enum LocaleEnum {
  en = 'en',
  fr = 'fr'
}
