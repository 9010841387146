import { API } from 'aws-amplify'
import axios from 'axios'

export default function customAnalyiticsEvents(userConfig = {}) {
  return {
    name: 'custom-analytics-events',
    config: userConfig,
    track: async ({ payload }) => {
      // only call the API if user is logged in
      if (await hasCurrentUser()) {
        await API.post('CoreEvent', '/core/analytics-track', { body: {
          eventType: payload.event,
          ...payload.properties,
        }} )
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/core/analytics-track-unauth`, {
          eventType: payload.event,
          ...payload.properties,
        })
      }
    },
  }
}

async function hasCurrentUser(): Promise<boolean> {
  try {
    await API.Auth.currentAuthenticatedUser()
    return true
  } catch(err) {
    return false
  }
}
