import React, { useEffect, useState } from 'react'
import { Tag } from 'antd'
import {sortBy, uniqBy } from 'lodash'
import { invertHexColor } from './utils/invert-color-wrapper'

import IntlMessages from './utils/IntlMessages'

interface LocationLeaveTypesTagProps {
  leaveTypes: ILeaveTypesShort[]
}

export interface ILeaveTypesShort {
  name: string | Element
  color: string
  position: number
  hideLeaveType: boolean
  id?: string
}

interface ILeaveTypesShortWithInvertedColor extends ILeaveTypesShort {
  invertedColor: string
}


const LocationLeaveTypesTag = ({ leaveTypes }: LocationLeaveTypesTagProps): React.ReactElement => {
  const [leaveTypeTags, setLeaveTypeTags] = useState<ILeaveTypesShortWithInvertedColor[]>([])
  useEffect(() => {
    setLeaveTypeTags(sortBy(leaveTypes.map(leaveType => {
      return {
        ...leaveType,
        invertedColor: leaveType.hideLeaveType ? '#FFF' : invertHexColor(leaveType.color, true),
        id: `${leaveType.id}${leaveType.hideLeaveType ? '|hidden-leave-type' : ''}`
      }
    }), ['position']))
  }, [leaveTypes])

  return (
    <p>
      {uniqBy(leaveTypeTags, 'id').map(leaveType => {
        return <Tag
          key={leaveType.id}
          style={{ marginRight: 0, marginLeft: 5, marginBottom: 5 }}
          color={leaveType.color}>
          <span style={{color: leaveType.invertedColor}}>
            {leaveType.name}
          </span>
        </Tag>
      })}
      <Tag key="holidays" color="#7266ba" style={{ marginRight: 0, marginLeft: 5, marginBottom: 5 }}>
        <IntlMessages id="app.holidays" />
      </Tag>
    </p>
  )
}

export default LocationLeaveTypesTag
