import React, { useState } from 'react'
import { Button, Form, Input, Spin } from 'antd'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import qs from 'qs'
import { History } from 'history'
import { useIntl } from 'react-intl'

import IntlMessages from '../../util/IntlMessages'
import { wait } from '@vacationtracker/shared/functions/wait'
import { getCompanyAndUserInfo } from '../../graphql/custom-queries'
import { useAppDispatch } from '../../store/hooks'
import { setAuthCompany } from '../../store/auth-company-slice'
import { setAuthUser } from '../../store/auth-user-slice'
import { setUserId } from '../../store/user-id-slice'
import { logout } from '../../services/auth/logout-handler'
import { availableLanguages } from '@vacationtracker/shared/i18n'
import { setLocale } from '../../store/locale-slice'

import { IGetCompanyAndUserInfo } from '../../types/custom-queries'
import { FrontendUrls } from '../../types/urls'


type EmailFormValues = {
  userId: string
  email: string
}
type CodeFormValues = {
  code: string
}
interface ISignInAsUser {
  onStateChange: Function
  history: History
  location: {
    pathname: string
    search: string
  }
}

const SignInAsUser = ({ onStateChange, history, location }: ISignInAsUser): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()
  const [showEmailForm, setShowEmailForm] = useState(true)
  const [showCodeForm, setShowCodeForm] = useState(false)
  const [cognitoUser, setCognitoUser] = useState(null)
  const [email, setEmail] = useState<string | undefined>()

  const [signinInProgress] = useState(false)
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })

  const submitEmailForm = async (values: EmailFormValues): Promise<void> => {
    try {
      setShowEmailForm(false)
      setShowCodeForm(true)
      const signInResponse = await Auth.signIn(values.userId.trim())
      setEmail(values.email)
      if (signInResponse.challengeName === 'CUSTOM_CHALLENGE' && signInResponse.challengeParam.question === 'token') {
        const response = await Auth.sendCustomChallengeAnswer(signInResponse, values.email, { loginType: 'loginAsUser', method: 'email', email: values.email })
        setCognitoUser(response)
      }
    } catch(error) {
      console.log('ERROR SUBMIT EMAIL FORM', error)
    }
  }

  let numberOfRetry = 0
  const getCompanyAndUser = async (id: string) => {
    try {
      const response = await API.graphql(graphqlOperation(getCompanyAndUserInfo, { userId: id })) as IGetCompanyAndUserInfo
      if (response.data.getCompany && response.data.getUser && response.data.getUser.name) {
        dispatch(setAuthCompany(response.data.getCompany))
        dispatch(setAuthUser(response.data.getUser))
        if (response.data.getUser.locale) {
          dispatch(setLocale(availableLanguages[response.data.getUser.locale]))
        }
      } else {
        throw new Error('No current user, retry')
      }
    } catch (error) {
      console.log('ERROR GET COMPANY AND USER', error)
      if (numberOfRetry >= 10) {
        logout({
          onStateChange: onStateChange,
          history,
          reduxDispatch: dispatch,
          userId: id,
        })
      } else if (![FrontendUrls.signin, FrontendUrls.signup].includes(location.pathname as FrontendUrls)) {
        numberOfRetry++
        await wait(200 * numberOfRetry)
        return await getCompanyAndUser(id)
      }
    }
  }

  const submitCodeForm = async (values: CodeFormValues) => {
    if (!email) {
      setShowEmailForm(true)
      setShowCodeForm(false)
      return false
    }
    const response = await Auth.sendCustomChallengeAnswer(cognitoUser, values.code, { loginType: 'loginAsUser', method: 'code', email })
    localStorage.setItem('userId', response.username)
    dispatch(setUserId(response.username))
    await getCompanyAndUser(response.username)
    onStateChange('signedIn')
  }

  return (
    <div className="auth-wrap">
      <div className="auth-container" style={{ width: 380 }}>
        <Spin spinning={signinInProgress}>
          {queryParams?.error === 'subscription_expired' && <IntlMessages id="error.subscriptionExpired" />}
          <div className="auth-sidebar-content-button" style={{ height: 200 }}>
            {showEmailForm &&
              <Form
                layout="vertical"
                onFinish={values => submitEmailForm(values as EmailFormValues)}
              >
                <Form.Item
                  label="User ID"
                  name="userId"
                  rules={[
                    { required: true, type: 'string', message: formatMessage({ id: 'signInAsUser.userIdIsRequired' }) },
                  ]}
                >
                  <Input
                    placeholder={formatMessage({ id: 'signInAsUser.userId' })}
                  />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, type: 'email', message: formatMessage({ id: 'signInAsUser.emailIsRequired' }) },
                  ]}
                >
                  <Input
                    placeholder={formatMessage({ id: 'signInAsUser.email' })}
                  />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" type="primary">Send code</Button>
                </Form.Item>
              </Form>
            }
            {showCodeForm &&
              <Form
                layout="vertical"
                onFinish={values => submitCodeForm(values as CodeFormValues)}
              >
                <Form.Item
                  label="Code"
                  name="code"
                  rules={[
                    { required: true, type: 'string', message: formatMessage({ id: 'signInAsUser.codeIsRequired' }) },
                  ]}
                >
                  <Input placeholder={formatMessage({ id: 'signInAsUser.enterTheCode' })} autoComplete='one-time-code' inputMode='numeric' />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" type="primary">Sign in</Button>
                </Form.Item>
              </Form>
            }
          </div>
        </Spin>
      </div>
    </div>
  )
}

export default SignInAsUser
