import { BrowserRuntime } from '../../../util/openid/browser-runtime'
import { parse } from 'qs'
import jwtDecode from 'jwt-decode'
import * as Sentry from '@sentry/react'

import {
  IGoogleApiPeopleList,
  IGoogleOAuthTokens,
  IGoogleOpenIdUser,
  IGoogleTokenInfo
} from '@vacationtracker/shared/types/google'
import { IImportUser } from '@vacationtracker/shared/types/user'
import { addMinutes } from 'date-fns'
import { IGoogleCalendarListResponse } from '@vacationtracker/shared/types/calendar'

export class GoogleAuth {
  private googleOauthUrl = 'https://accounts.google.com/o/oauth2/v2/auth'
  private apiUrl = `${process.env.REACT_APP_API_URL}/google`
  private targetUrl = `${window.location.origin}/googleredirect/index.html`
  private runtime = BrowserRuntime()
  private clientId?: string
  private tokens: IGoogleOAuthTokens = {}
  private scopes = [
    'openid',
    'https://www.googleapis.com/auth/userinfo.email',
    'https://www.googleapis.com/auth/userinfo.profile',
    'https://www.googleapis.com/auth/directory.readonly',
  ]

  private extendedScopes = [
    'openid',
    'https://www.googleapis.com/auth/userinfo.email',
    'https://www.googleapis.com/auth/userinfo.profile',
    'https://www.googleapis.com/auth/directory.readonly',
    'https://www.googleapis.com/auth/admin.directory.user.readonly',
  ]

  private outOfOfficeScopes = [
    'openid',
    'https://www.googleapis.com/auth/userinfo.email',
    'https://www.googleapis.com/auth/userinfo.profile',
    'https://www.googleapis.com/auth/gmail.settings.basic',
    'https://www.googleapis.com/auth/calendar',
  ]

  constructor(clientId?: string) {
    if (clientId) {
      this.clientId = clientId
      this.setTokensFromStorage()
    } else {
      throw new Error('Google client id is required')
    }
  }

  async signin(extendsSignin = false) {
    const scope = extendsSignin ? this.extendedScopes.join(' ') : this.scopes.join(' ')
    // eslint-disable-next-line max-len
    let url =`${this.googleOauthUrl}?client_id=${this.clientId}&redirect_uri=${this.targetUrl}&response_type=code&access_type=offline&include_granted_scopes=true&scope=${scope}`
    if (!extendsSignin || !sessionStorage.getItem('googleRefreshToken')) {
      url += '&prompt=consent'
    }
    const payloadRaw = await this.runtime.openAndWaitForMessage(url, 'google')
    const payload = parse(payloadRaw.slice(1))

    if(!payload.code || typeof payload.code !== 'string') {
      throw payload
    }

    const refreshToken = sessionStorage.getItem('googleRefreshToken')
    const tokenResponse = await this.exchangeCodeForTokens(payload.code)

    if (!tokenResponse.refreshToken) {
      tokenResponse.refreshToken = refreshToken
    }
    this.setTokens(tokenResponse, addMinutes(new Date(), 15).toString())
  }

  async grantCalendarPermission() {
    const scopes = ['https://www.googleapis.com/auth/calendar']

    const payloadRaw = await this.runtime.openAndWaitForMessage(
      // eslint-disable-next-line max-len
      `${this.googleOauthUrl}?client_id=${this.clientId}&redirect_uri=${this.targetUrl}&response_type=code&access_type=offline&include_granted_scopes=true&scope=${scopes}&prompt=consent`,
      'google'
    )
    const payload = parse(payloadRaw.slice(1))

    if (!payload.code || typeof payload.code !== 'string') {
      throw payload
    }

    const tokenResponse = await this.exchangeCodeForTokens(payload.code)
    return tokenResponse
  }

  async getGoogleCalendars(accessToken: string): Promise<IGoogleCalendarListResponse | null> {
    const response = await fetch('https://www.googleapis.com/calendar/v3/users/me/calendarList', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    })

    if (response.status === 401) {
      console.log('Token expired')
      await this.grantCalendarPermission()
    }

    if (response.status === 403) {
      throw new Error('GOOGLE_DIRECTORY_PERMISSION_DENIED')
    }

    if (!response.ok || response.status !== 200) {
      throw new Error(await response.text())
    }
    return response.json()
  }

  private async exchangeCodeForTokens(code: string): Promise<IGoogleOAuthTokens> {
    const response = await fetch(`${this.apiUrl}/get-token?code=${code}&redirectUrl=${this.targetUrl}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (!response.ok) {
      throw response.statusText
    }

    return response.json()
  }

  private setTokens(tokens: IGoogleOAuthTokens, expiration: string): void {
    this.tokens = tokens
    sessionStorage.setItem('googleAccessToken', tokens.accessToken ?? '')
    sessionStorage.setItem('googleIdToken', tokens.idToken ?? '')
    sessionStorage.setItem('googleRefreshToken', tokens.refreshToken?? '')
    sessionStorage.setItem('googleTokenExpiration', expiration)
  }

  private setTokensFromStorage() {
    this.tokens = {
      refreshToken: sessionStorage.getItem('googleRefreshToken'),
      idToken: sessionStorage.getItem('googleIdToken'),
      accessToken: sessionStorage.getItem('googleAccessToken'),
    }
  }

  private getTokens(): IGoogleOAuthTokens {
    this.tokens = {
      refreshToken: sessionStorage.getItem('googleRefreshToken'),
      idToken: sessionStorage.getItem('googleIdToken'),
      accessToken: sessionStorage.getItem('googleAccessToken'),
    }

    return this.tokens
  }

  getAccessToken(): string {
    const token = this.getTokens().accessToken
    if (!token){
      console.error('No access token')
      throw new Error('no_tokens')
    }

    return token
  }

  getRefreshToken(): string {
    const token = this.getTokens().refreshToken
    if (!token){
      console.error('No refresh token')
      throw new Error('no_tokens')
    }

    return token
  }

  getIdToken(): string {
    const token = this.getTokens().idToken
    if (!token){
      console.error('No id token')
      throw new Error('no_tokens')
    }

    return token
  }

  getSignedInUser(): IGoogleOpenIdUser {
    const idToken = this.tokens.idToken
    if (!idToken) {
      throw new Error('Id token missing. Sign in again')
    }

    return jwtDecode<IGoogleOpenIdUser>(idToken)
  }

  private async getUserListFromApi(nextPageToken?: string): Promise<IGoogleApiPeopleList> {
    // eslint-disable-next-line max-len
    const response = await fetch(`https://people.googleapis.com/v1/people:listDirectoryPeople?readMask=names,emailAddresses,photos&sources=DIRECTORY_SOURCE_TYPE_DOMAIN_PROFILE&pageSize=200${nextPageToken ? `&pageToken=${encodeURIComponent(nextPageToken)}`:''}`, {
      headers: {
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
    })

    if (response.status === 401) {
      console.log('Token expired')
      await this.signin()
      return this.getUserListFromApi()
    }

    if (response.status >= 400 && response.status !== 401) {
      const res = await response.text()
      console.log('Error getting user list', res)
      Sentry.captureException(new Error(res))
    }

    if (response.status === 403) {
      throw new Error('GOOGLE_DIRECTORY_PERMISSION_DENIED')
    }

    if (!response.ok || response.status !== 200) {
      throw new Error(await response.text())
    }
    return response.json()
  }

  async getUserListForImportPaged(nextPageToken?: string): Promise<{users: IImportUser[]; nextPageToken?: string}> {
    const userListResult = await this.getUserListFromApi(nextPageToken)

    if (!userListResult?.people) {
      throw new Error('GOOGLE_DIRECTORY_SHARING_DISABLED')
    }

    const users: IImportUser[] = userListResult.people
      .filter(googlePerson => googlePerson.emailAddresses && googlePerson.emailAddresses.length > 0)
      .map(googlePerson => {
        const emailObj = googlePerson.emailAddresses?.find(emailObj => emailObj.metadata.primary)
        const nameObj = googlePerson.names?.find(nameObj => nameObj.metadata.primary)
        const photoObj = googlePerson.photos?.find(photoObj => photoObj.metadata.primary)
        const googleId = googlePerson.resourceName.split('/')[1]
        const email = emailObj?.value ?? ''
        const name = (nameObj?.givenName || nameObj?.familyName) ? `${nameObj?.givenName || ''} ${nameObj?.familyName}` : (nameObj?.displayName ?? email?.split('@')[0])
        return {
          id: `google-${googleId}`,
          googleId,
          email,
          name,
          image: photoObj?.url,
        }
      })

    return {
      users,
      nextPageToken: userListResult.nextPageToken,
    }
  }

  async getUserListForImportFull(nextPageToken?: string): Promise<IImportUser[]> {
    const thisPage = await this.getUserListForImportPaged(nextPageToken)

    if (!thisPage.nextPageToken) {
      return thisPage.users
    } else {
      const nextPageUsers = await this.getUserListForImportFull(thisPage.nextPageToken)
      return thisPage.users.concat(nextPageUsers)
    }
  }

  async tokenInfo(): Promise<IGoogleTokenInfo> {
    const response = await fetch(`https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${this.getAccessToken()}`)

    if (response.status === 401) {
      console.log('Token expired')
      await this.signin()
      return this.tokenInfo()
    }

    if (!response.ok || response.status !== 200) {
      throw new Error(await response.text())
    }
    return response.json()
  }

  async userInfo(accessToken: string) {
    const response = await fetch(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${accessToken}`)

    if (!response.ok || response.status !== 200) {
      throw new Error(await response.text())
    }
    return response.json()
  }

  async getGoogleCalendarTimezone(accessToken: string) {
    const response = await fetch(`https://www.googleapis.com/calendar/v3/calendars/primary?access_token=${accessToken}`)

    if (!response.ok || response.status !== 200) {
      throw new Error(await response.text())
    }
    const calendarResponse = await response.json()

    return calendarResponse.timeZone
  }

  async grantGmailPermission() {
    const scopes = this.outOfOfficeScopes.join(' ')

    const payloadRaw = await this.runtime.openAndWaitForMessage(
      // eslint-disable-next-line max-len
      `${this.googleOauthUrl}?client_id=${this.clientId}&redirect_uri=${this.targetUrl}&response_type=code&access_type=offline&include_granted_scopes=true&scope=${scopes}&prompt=consent`,
      'google'
    )
    const payload = parse(payloadRaw.slice(1))

    if (!payload.code || typeof payload.code !== 'string') {
      throw payload
    }

    const tokenResponse = await this.exchangeCodeForTokens(payload.code)
    return tokenResponse
  }
}
