import * as moment from 'moment-timezone'

export const DateLocalToUtc = (minutes, hours, weekDay, timezone) => {
  const date = moment.tz(timezone).day(weekDay).hours(hours).minutes(minutes).utc()
  return {
    hour: Number(date.format('HH')),
    day: Number(date.format('d')),
    minute: Number(date.format('mm')),
  }
}

export const DateUtcToLocal = (minutes, hours, weekDay, timezone) => {
  const date = moment.utc().day(weekDay).hours(hours).minutes(minutes).tz(timezone)
  return {
    hour: date.format('HH'),
    day: date.format('d'),
    minute: date.format('mm'),
  }
}

export const TimeLocalToUtc = (minutes, hours, timezone) => {
  const date = moment.tz(timezone).hours(hours).minutes(minutes).utc()
  return {
    hour: Number(date.format('HH')),
    minute: Number(date.format('mm')),
  }
}

export const TimeUtcToLocal = (minutes, hours, timezone) => {
  const date = moment.utc().hours(hours).minutes(minutes).tz(timezone)
  return {
    hour: date.format('HH'),
    minute: date.format('mm'),
  }
}
