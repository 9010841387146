import React, { useState } from 'react'
import { Modal, Form, Input } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import IntlMessages from '../../util/IntlMessages'
import { IUserPendingLeaves } from '../../types/custom-queries'
import { IDashboardLeaves } from '../../types/dashboard'
import { IWallchartUserLeavesData } from '../../types/wallchart'

interface IDenyWithReasonForm {
  onSave: (data) => Promise<void>
  handleCancel: () => void
  visibleModal: boolean
  leaveRequest: IDashboardLeaves | IUserPendingLeaves | IWallchartUserLeavesData
}

const DenyWithReasonForm = ({ onSave, handleCancel, visibleModal, leaveRequest }: IDenyWithReasonForm): React.ReactElement => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const { TextArea } = Input

  const onFinish = () => {
    form
      .validateFields()
      .then(values => {
        setIsLoading(true)
        onSave({
          statusReason: values.statusReason,
          leaveRequest,
        })
      })
      .catch(info => {
        console.log('Validate Failed:', info)
      })
  }

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  return (
    <Modal
      title={<IntlMessages id="app.denyLeaveRequest" />}
      visible={visibleModal}
      closeIcon={<CloseCircleOutlined />}
      onCancel={handleCancel}
      confirmLoading={isLoading}
      okText={<IntlMessages id="app.deny" />}
      onOk={onFinish}
      cancelText={<IntlMessages id="app.cancel" />}
    >
      <Form
        key="denyWithReasonForm"
        form={form}
        name="denyWithReasonForm"
        onFinish={onFinish}
      >
        <Form.Item
          {...formItemLayout}
          name="statusReason"
          label={<IntlMessages id="components.denyWithReasonForm.reason" />}
          rules={[{ required: true, message: <IntlMessages id="error.fieldIsRequired" /> }]}
        >
          <TextArea rows={3} style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default DenyWithReasonForm
