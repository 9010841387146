import { Platform } from '@vacationtracker/shared/types/core-event'

declare global {
  interface Window {
    $crisp: any[] // eslint-disable-line @typescript-eslint/no-explicit-any
    gtag: any // eslint-disable-line @typescript-eslint/no-explicit-any
  }
}

export const setCrispSessionParams = (step: string): void => {
  try {
    if (!window.$crisp) {
      return
    }

    window.$crisp.push(['set', 'session:data', [
      ['signupStep', step],
    ]])
  } catch (err) {
    // Swallow errors
    console.debug(err)
  }
}

export const setCrispSessionInfoForSignupProcess = (platform: Platform) => {
  try {
    if (!window.$crisp) {
      return
    }

    // Set Crisp segments, i.e., "chat," "support," "dashboard," "slack," and "in-signup"
    const segments = ['chat', 'support', 'dashboard', 'in-signup']
    segments.push(platform === 'microsoft' ? 'teams' : platform)
    window.$crisp.push(['set', 'session:segments', [segments, true]])
  } catch (err) {
    // Swallow errors
    console.debug(err)
  }
}