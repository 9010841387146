import React from 'react'
import './styles/theme.less'
import './assets/vendors/style'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import App from './containers/App/index'
import { Auth, Amplify } from 'aws-amplify'
import { Authenticator } from 'aws-amplify-react'

Amplify.configure({
  API: {
    // eslint-disable-next-line
    graphql_headers: async () => {
      const session = await Auth.currentSession()
      return {
        Authorization: session.getIdToken().getJwtToken(),
      }
    },
    endpoints: [
      {
        name: 'CoreEvent',
        endpoint: process.env.REACT_APP_API_URL,
        // eslint-disable-next-line
        custom_header: async () => {
          const session = await Auth.currentSession()
          return {
            Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
          }
        },
      },
      {
        name: 'SlackApi',
        endpoint: `${process.env.REACT_APP_API_URL}/slack`,
        // eslint-disable-next-line
        custom_header: async () => {
          const session = await Auth.currentSession()
          return {
            Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
          }
        },
      },
      {
        name: 'GoogleApi',
        endpoint: `${process.env.REACT_APP_API_URL}/google`,
        // eslint-disable-next-line
        custom_header: async () => {
          const session = await Auth.currentSession()
          return {
            Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
          }
        },
      },
      {
        name: 'MicrosoftApi',
        endpoint: `${process.env.REACT_APP_API_URL}/microsoft`,
        // eslint-disable-next-line
        custom_header: async () => {
          const session = await Auth.currentSession()
          return {
            Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
          }
        },
      },
      {
        name: 'StripeApi',
        endpoint: `${process.env.REACT_APP_API_URL}/stripe`,
        // eslint-disable-next-line
        custom_header: async () => {
          const session = await Auth.currentSession()
          return {
            Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
          }
        },
      },
      {
        name: 'MicrosoftSaasApi',
        endpoint: `${process.env.REACT_APP_API_URL}/microsoft-billing`,
        // eslint-disable-next-line
        custom_header: async () => {
          const session = await Auth.currentSession()
          return {
            Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
          }
        },
      },
    ],
  },
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    mandatorySignIn: true,
  },
  // eslint-disable-next-line
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  // eslint-disable-next-line
  aws_appsync_region: process.env.REACT_APP_REGION,
  // eslint-disable-next-line
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
})

const AppWithAuthenticator = (props) => {
  return (
    <Route path="/" render={(routerProps) => <App {...props} {...routerProps} /> } />
  )
}

interface IMainApp {
  location: {
    pathname: string
  }
}

const MainApp = () =>
  <BrowserRouter>
    <Switch>
      <Route render={
        (props: IMainApp)  =>
          <Authenticator authState={
            (props.location.pathname.includes('create-company') || props.location.pathname === '/signup') ? 'signUp' : 'signIn'
          } hideDefault={true}>
            <AppWithAuthenticator />
          </Authenticator>
      }></Route>
    </Switch>
  </BrowserRouter>

export default MainApp
