import { ICalendarSyncState, CalendarSyncActionsEnum, CalendarSyncViewEnum } from './types'


export const initialState: ICalendarSyncState = {
  isFetchingData: true,
  calendars: [],
  filteredCalendars: [],
  locations: [],
  departments: [],
  labels: [],
  calendarToConnectType: null,
  initialFilterValues: [{}],
  filters: {
    locationIds: [],
    teamIds: [],
    labelIds: [],
  },
  calendar: null,
  calendarToConnect: null,
  googleSyncCalendarData: null,
  outlookSyncCalendarData: null,
  whatToRender: CalendarSyncViewEnum.calendars,
}

export const reducer = (state: ICalendarSyncState, action) => {
  switch (action.type) {
    case CalendarSyncActionsEnum.getCalendarsStart:
      return {
        ...state,
        isFetchingData: true,
        calendars: [],
        filteredCalendars: [],
        locations: [],
        departments: [],
        labels: [],
      }
    case CalendarSyncActionsEnum.getCalendarsSuccess:
      return {
        ...state,
        ...action.payload,
        isFetchingData: false,
        filteredCalendars: action.payload.calendars,
      }
    case CalendarSyncActionsEnum.setFilteredCalendars:
      return {
        ...state,
        isFetchingData: false,
        filteredCalendars: action.payload,
      }
    case CalendarSyncActionsEnum.setCalendarToConnectType:
      return {
        ...state,
        calendarToConnectType: action.payload,
      }
    case CalendarSyncActionsEnum.setWhatToRender:
      return {
        ...state,
        whatToRender: action.payload,
      }
    case CalendarSyncActionsEnum.setFilters:
      return {
        ...state,
        filters: action.payload,
      }
    case CalendarSyncActionsEnum.setInitialFilters:
      return {
        ...state,
        initialFilterValues: action.payload,
      }
    case CalendarSyncActionsEnum.setCalendar:
      return {
        ...state,
        calendar: action.payload,
      }
    case CalendarSyncActionsEnum.setIsCreatingCalendar:
      return {
        ...state,
        isCreatingCalendar: action.payload,
      }
    case CalendarSyncActionsEnum.setGoogleSyncCalendarData:
      return {
        ...state,
        googleSyncCalendarData: action.payload,
        outlookSyncCalendarData: null,
      }
    case CalendarSyncActionsEnum.setOutlookSyncCalendarData:
      return {
        ...state,
        outlookSyncCalendarData: action.payload,
        googleSyncCalendarData: null,
      }
    case CalendarSyncActionsEnum.setCalendarToConnect:
      return {
        ...state,
        calendarToConnect: action.payload,
      }
    default:
      return state
  }
}