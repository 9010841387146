import React, { useState } from 'react'
import QRCode from 'qrcode.react'
import { Modal, Form, Typography } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import IntlMessages from '@vacationtracker/shared/components/utils/IntlMessages'
import MFACodeInput from '../mfa-code'

interface EnableMFAModalProps {
  secretCode: string
  onClose: () => void
  onSave: (code: string) => void
}

const EnableMFAModal = ({ secretCode, onClose, onSave }: EnableMFAModalProps): React.ReactElement => {

  const [form] = Form.useForm()
  const { Text } = Typography
  const [isLoading, setIsLoading] = useState(false)

  const onFinish = () => {
    form
      .validateFields()
      .then(values => {
        setIsLoading(true)
        onSave(values.code as string)
      })
      .catch(info => {
        console.log('Validate Failed:', info)
      })
  }

  return (
    <Modal
      visible={true}
      onCancel={onClose}
      onOk={onFinish}
      confirmLoading={isLoading}
      title={<IntlMessages id="app.mfaTitle" />}  
      closeIcon={<CloseCircleOutlined />}
      okText={<IntlMessages id="app.save" />}
      cancelText={<IntlMessages id="app.cancel" />}
    >
      <div><IntlMessages id="app.mfaSubTitle" /></div>
      <div style={{ textAlign: 'center', marginTop: '10px' }}>
        <QRCode style={{ border: '5px solid white' }} value={`otpauth://totp/VacationTracker?secret=${secretCode}`} />
      </div>
      <div style={{ marginTop: '10px' }}><IntlMessages id="app.mfaSecretKey"/></div>
      <Form
        key="EnableMFAForm"
        form={form}
        name="EnableMFAForm"
      >
        <Form.Item name="secret">
          <Text style={{ fontSize: '13px' }} type="secondary" copyable={{text: secretCode}} >
            {secretCode}&nbsp;
          </Text>
        </Form.Item>
        <div style={{ marginBottom: '15px' }}><IntlMessages id="app.mfaTooltip"/></div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <MFACodeInput handleEnter={onFinish} />
        </div>
      </Form>
    </Modal>
  )
}

export default EnableMFAModal