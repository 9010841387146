import React, { useContext, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { notification } from 'antd'
import { API, graphqlOperation } from 'aws-amplify'
import { Observable, ZenObservable } from 'zen-observable-ts'
import { cloneDeep } from 'lodash'

import { notificationStore } from '../../context/notificationsContext/store'
import { errorSubscription } from '../../graphql/custom-queries'
import { generateNotificationInfo, handleUnsubscribe } from '../../util/notifications'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { selectUserIdSlice } from '../../store/user-id-slice'

import { ISubscriptionEventMessage } from '@vacationtracker/shared/types/notification'
import { setFetchOnboarding } from '../../store/onboarding-slice'

let delay = 15

const Notifications = () => {
  const { actionNotifications, setActionNotifications, eventsNotifications, setEventsNotifications } = useContext(notificationStore)
  const { userId } = useAppSelector(selectUserIdSlice)
  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()
  const actionNotificationsRef = useRef(actionNotifications)
  const eventsNotificationsRef = useRef(eventsNotifications)
  const sub = useRef<ZenObservable.Subscription[]>([])

  const handleSubscription = () => {
    const subscription = API.graphql(
      graphqlOperation(errorSubscription, { userId })
    ) as Observable<object>

    const subs = subscription.subscribe({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      next: (data: any) => {
        delay = 10
        let subscriptionEvent: ISubscriptionEventMessage | null = null
        try {
          subscriptionEvent = JSON.parse(data.value.data.eventResponses.originalEvent)
        } catch(err) {
          // Ignoring the error
        }

        // Ignore if event not created from dashboard
        if (subscriptionEvent?.source !== 'dashboard') {
          return
        }

        const eventType = subscriptionEvent.eventType
        if (eventType === 'LEAVE_REQUEST_CREATED' || eventType === 'NOTIFICATION_CREATED' || eventType === 'USER_STATUS_CHANGED') {
          dispatch(setFetchOnboarding(subscriptionEvent.correlationId))
        }

        if (
          data?.value?.data?.eventResponses?.code === 'error' ||
            (
              subscriptionEvent &&
              Array.isArray(actionNotificationsRef.current) &&
              (
                subscriptionEvent.correlationId &&
                actionNotificationsRef.current.includes(subscriptionEvent.correlationId)
              )
            )
        ) {
          const notifications = updateNotification(data, cloneDeep(actionNotificationsRef.current))
          setActionNotifications(notifications ?? [])
        }

        if (
          data?.value?.data?.eventResponses?.code === 'error' ||
          (
            subscriptionEvent && subscriptionEvent.correlationId && eventsNotificationsRef.current &&
            (
              eventsNotificationsRef.current[subscriptionEvent.correlationId] && 
              eventsNotificationsRef.current[subscriptionEvent.correlationId].eventType === subscriptionEvent.eventType
            ) || eventsNotificationsRef.current[`${subscriptionEvent.correlationId}#${subscriptionEvent.eventType}`]
          )
        ) {
          const notifications = updateNotification(data, cloneDeep(eventsNotificationsRef.current))
          setEventsNotifications(notifications ?? [])
        }
      },
      error: () => {
        if ((delay * 2) < 3.6e+6) {
          delay *= 2
        }
        setTimeout(() => {
          handleSubscription()
        }, delay)
      },
    })

    return subs
  }

  useEffect(() => {
    actionNotificationsRef.current = actionNotifications
  }, [actionNotifications])

  useEffect(() => {
    eventsNotificationsRef.current = eventsNotifications
  }, [eventsNotifications])

  useEffect(() => {
    if (!userId) {
      handleUnsubscribe(sub.current)
      return
    }
    sub.current.push(handleSubscription())
    return () => {
      handleUnsubscribe(sub.current)
    }
  }, [userId])

  const updateNotification = (data, eventNotificationsRef): { key: string } | void | object => {
    const { action, notifications, options: { title,
      titleValues,
      message,
      description,
      descriptionValues,
      btn,
      duration,
      key,
      updateNotification,
      forceErrorMessage = false,
    } } = generateNotificationInfo(data.value.data.eventResponses, eventNotificationsRef, formatMessage)
    if (!updateNotification || (!title && action !== 'error')) {
      return
    }
    const messageValue = title && formatMessage({ id: title }, titleValues) ?
      formatMessage({ id: title }, titleValues) :
      /^error\.[a-zA-Z0-9]+$/.test(message) ?
        formatMessage({ id: message }) :
        message

    const errorMessage = action === 'error' ? formatMessage({ id: 'notifications.error' }, { correlationId: key }) : ''

    let descriptionValue

    if (typeof description !== 'object') {
      descriptionValue = description && `${formatMessage({ id: description }, descriptionValues)} ${errorMessage}`
    } else {
      descriptionValue = <>{description} {errorMessage}</>
    }
    if (forceErrorMessage) {
      descriptionValue = <>{errorMessage}</>
    }

    if (action === 'close') {
      notification.close(key)
      return notifications
    }

    notification[action]({
      key,
      message: messageValue,
      description: descriptionValue,
      btn,
      duration,
    })

    return notifications
  }

  return (<></>)
}

export default Notifications
