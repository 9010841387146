import { IAvailableLanguages, LocaleEnum } from '../types/i18n'
import { fr, enUS } from 'date-fns/locale'
import { Locale } from 'date-fns'


export const availableLanguages: IAvailableLanguages = {
  en: {
    locale: LocaleEnum.en,
    name: 'English',
    icon: '🇺🇸',
  },
  fr: {
    locale: LocaleEnum.fr,
    name: 'Français',
    icon: '🇫🇷',
  },
}

export const getDateFnsLocale = (locale: LocaleEnum): Locale => {
  switch (locale) {
    case LocaleEnum.en:
      return enUS
    case LocaleEnum.fr:
      return fr
    default:
      return enUS
  }
}

export const getSafeLocale = (locale: LocaleEnum | null | undefined) => locale || LocaleEnum.en
