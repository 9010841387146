import enLang from './entries/en-US'
import frLang from './entries/fr-FR'

export interface ILocale {
  locale: string
  name: string
  icon: string
}

export interface IAvailableLanguages {
  [key: string]: ILocale
}

const AppLocale = {
  en: enLang,
  fr: frLang,
}


export const availableLanguages: IAvailableLanguages = {
  en: {
    locale: 'en',
    name: 'English',
    icon: '🇺🇸',
  },
  fr: {
    locale: 'fr',
    name: 'Français',
    icon: '🇫🇷',
  },
}

export default AppLocale
