import { HourFormatEnum } from '../types/user'
import timezones from '../data/timezone'

export const getHoursAndAmOrPmMap = () => Array.from(Array(24).keys()).map(v => ({
  value: v === 0 || v === 12 ? 12 : v % 12,
  amOrPm: v < 12 ? 'AM' : 'PM',
}))

export const convertHourFormats = (
  hourFormat: HourFormatEnum,
  hour: number,
  hoursMap = getHoursAndAmOrPmMap()
): {
  value: number
  amOrPm: string
} => {
  if (hourFormat === HourFormatEnum.twentyFour) {
    return {value: hour, amOrPm: ''}
  } else {
    return hoursMap[hour]
  }
}

export const getHourFormatBasedOnTimezone = (timezoneId?: string) => {
  if (!timezoneId) {
    return HourFormatEnum.twentyFour
  }
  return timezones.find(timezone => timezone.id === timezoneId)?.hourFormat || HourFormatEnum.twentyFour
}

export const get12HourFormatTime = (time: number, amOrPm: string) => {
  if (amOrPm === 'AM') {
    return time
  } else {
    return time + 12
  }
}

export const formatHourForHalfDaySelect = (hour: number, hourFormat: HourFormatEnum): string => {
  if (hourFormat === HourFormatEnum.twentyFour) {
    return (hour < 10 ? `0${hour}:00` : `${hour}:00`) + ' to ' + (hour + 4 < 10 ? `0${hour + 4}:00` : `${hour + 4}:00`)
  } else {
    const startHour = hour === 0 || hour === 12 ? 12 : hour % 12
    const startPeriod = hour < 12 ? 'AM' : 'PM'
    const endHour = (hour + 4) === 0 || (hour + 4) === 12 ? 12 : (hour + 4) % 12
    const endPeriod = (hour + 4) < 12 ? 'AM' : 'PM'

    return `${startHour}:00 ${startPeriod} to ${endHour}:00 ${endPeriod}`
  }
}
