/* eslint-disable max-len */
import React from 'react'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'

import { computeDaysAndHours, getFormattedDaysAndHours } from '../../functions/get-days-or-hours'

import {
  IDisplayLeaveInDaysAndHours,
  IDisplayLeaveInDaysAndHoursBots,
  LeaveDisplayFormatEnum
} from '../../types/display-in-days-hours'
import { IToilRequest } from '../../types/toil-request'


export const DisplayLeaveInDaysAndHours = ({
  doNotConvertToHours = false,
  value,
  format = LeaveDisplayFormatEnum.long,
  numberOfWorkingHoursPerDay = 8,
}: IDisplayLeaveInDaysAndHours): React.ReactElement => {
  const { formatMessage } = useIntl()
  if (value === 0) {
    return <>{value}</>
  }

  const val = doNotConvertToHours ? value : value * numberOfWorkingHoursPerDay
  const leave = computeDaysAndHours(val)

  return <>
    {getFormattedDaysAndHours({
      leave,
      formatMessage,
      displayFormat: format,
    })}
  </>
}


export const displayLeaveInDaysAndHours = ({
  doNotConvertToHours = false,
  value,
  format = LeaveDisplayFormatEnum.long,
  formatMessage,
  numberOfWorkingHoursPerDay = 8,
}: IDisplayLeaveInDaysAndHoursBots) => {
  if (value === 0) {
    return `${value}`
  }
  const val = doNotConvertToHours ? value : value * numberOfWorkingHoursPerDay
  const leave = computeDaysAndHours(val)
  return getFormattedDaysAndHours({
    leave,
    formatMessage,
    displayFormat: format,
  })
}

export const displayToilInDaysAndHours = (formatMessage, toilRequest: IToilRequest) => {
  const amount = toilRequest.partDayStartHour 
    ? (toilRequest.partDayEndHour as number - toilRequest.partDayStartHour as number)
    : dayjs(toilRequest.endDate as string).diff(toilRequest.startDate as string, 'days') + 1
  
  const unit = toilRequest.partDayStartHour 
    ? formatMessage({ id: amount === 1 ? 'app.hour' : 'app.hours' })
    : formatMessage({ id: amount === 1 ? 'app.day' : 'app.days' })

  return `${amount} ${unit}`
}
