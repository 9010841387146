import { IError } from './types'


export const InviteAcceptedException: IError = {
  code: 'InviteAcceptedException',
  message: 'Can not delete or accept invitation that has been already accepted',
}

export const InvitationNotFoundException: IError = {
  code: 'InvitationNotFoundException',
  message: 'Invitation does not exist',
}

export const InvitationExpiredException: IError = {
  code: 'InvitationExpiredException',
  message: 'Invitation not valid anymore',
}

export const CannotAcceptInvitationException: IError = {
  code: 'CannotAcceptInvitationException',
  message: 'You cannot accept this invitation because you are a member of another Vacation Tracker organization or your account is not active.',
}

export const CannotExpireInvitationException: IError = {
  code: 'CannotExpireInvitationException',
  message: 'There was a problem with invitation expiring.',
}

export const CannotDuplicateLeaveTypeName: IError = {
  code: 'CannotDuplicateLeaveTypeName',
  message: 'Cannot duplicate leave type name',
}

export const CanNotSetOutOfOffice: IError = {
  code: 'CanNotSetOutOfOfficeException',
  message: 'Cannot set out of office',
}

export const CanNotActivateUserSeatLimit: IError = {
  code: 'CanNotActivateUserSeatLimitException',
  message: 'Cannot activate user(s), seat limit reached',
}

export const LeaveTypeDeleted: IError = {
  code: 'LeaveTypeDeleted',
  message: 'Leave type deleted',
}

export const LabelDeleted: IError = {
  code: 'LabelDeleted',
  message: 'Label deleted',
}

export const LocationDeleted: IError = {
  code: 'LocationDeleted',
  message: 'Location deleted',
}
