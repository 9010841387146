import React, { Dispatch, SetStateAction, useState } from 'react'
import { Modal, Typography, Input } from 'antd'

import IntlMessages from '../../util/IntlMessages'
import { CancelReasonEnum } from '@vacationtracker/shared/types/billing'


const { Paragraph } = Typography
const { TextArea } = Input

interface ICancelTrialSubscriptionModal {
  openCancelSubscriptionModal: boolean
  setOpenCancelTrialSubscriptionModal: Dispatch<SetStateAction<boolean>>
  handleOnCancelSubscription: (reason: CancelReasonEnum, comment: string, integrationWithHr?: string, movingToPayroll?: string) => Promise<void> | void
  closeModal: () => void
  setIsTrialCanceled: Dispatch<SetStateAction<boolean>>
  hasPaymentInfo: boolean
}

export const CancelTrialSubscriptionModal = ({
  openCancelSubscriptionModal,
  setOpenCancelTrialSubscriptionModal,
  handleOnCancelSubscription,
  closeModal,
  setIsTrialCanceled,
  hasPaymentInfo,
}: ICancelTrialSubscriptionModal): React.ReactElement => {
  const [cancelReasonComment, setCancelReasonComment] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Modal
      title={<IntlMessages id="components.cancelSubscriptionModal.title" />}
      visible={openCancelSubscriptionModal}
      onOk={() => {
        if (!localStorage.getItem('isTrialCanceled') && !hasPaymentInfo) {
          localStorage.setItem('isTrialCanceled', 'true')
          setOpenCancelTrialSubscriptionModal(false)
          setIsTrialCanceled(true)
          return
        }
        setIsLoading(true)
        handleOnCancelSubscription(CancelReasonEnum.cancelTrial, cancelReasonComment)
      }}
      destroyOnClose={true}
      okText={<IntlMessages id="billing.cancelTrialButton" />}
      okButtonProps={{ danger: true, disabled: cancelReasonComment.length < 10 }}
      confirmLoading={isLoading}
      onCancel={() => closeModal()}
      cancelText={<IntlMessages id="components.cancelTrialSubscriptionModal.cancelText" />}
    >
      <Paragraph>
        <IntlMessages id="components.cancelTrialSubscriptionModal.info" />
      </Paragraph>
      <TextArea value={cancelReasonComment} onChange={e => setCancelReasonComment(e.target.value)} style={{ marginBottom: 0 }}/>
    </Modal>
  )
}

export default CancelTrialSubscriptionModal
