import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'

import VTLogoWhite from '../../assets/images/VT-Logo-White.svg'
import VTLogoPurple from '../../assets/images/VT-Logo-Purple.svg'
import { ThemeContext } from '../../context/themeContext'
import { DARK_THEME } from '../../constants/ThemeSetting'


const SidebarLogo = () => {
  const { miniMenu, setMiniMenu, theme } = useContext(ThemeContext)

  const onToggleMiniMenu = () => {
    setMiniMenu(!miniMenu)
  }

  return (
    <div className="layout-sider-header">
      <Button className="btn-linebar-menu" type="link" onClick={onToggleMiniMenu}>
        {miniMenu ?
          <MenuUnfoldOutlined /> :
          <MenuFoldOutlined />
        }
      </Button>

      <Link to="/app/dashboard" className="site-logo">
        {theme === DARK_THEME ? <VTLogoWhite/> : <VTLogoPurple/>}
      </Link>
    </div>
  )
}

export default SidebarLogo
