/* eslint-disable react/display-name */
import React, { useEffect, useState, useRef } from 'react'
import { useIntl } from 'react-intl'
import { Alert, Button, Card, Checkbox, List, Modal, Select, Spin, Typography, notification as antdNotification, Tooltip } from 'antd'
import { CheckSquareOutlined, MinusSquareOutlined, InfoCircleOutlined } from '@ant-design/icons'
import InfiniteScroll from 'react-infinite-scroller'
import find from 'lodash/find'
import unionBy from 'lodash/unionBy'

import IntlMessages from '../../util/IntlMessages'
import { Search } from '../search'
import CircularProgress from '../circular-progress'

const { Paragraph } = Typography

import { ParentComponentEnum, IManageAssignLicensesProps } from './types'
import { getTextWidth } from '../../util/get-text-width'
import { IMicrosoftUser } from '../../types/microsoft'


const ManageAssignLicenses = ({
  numberOflicenses,
  msAuth,
  onSelectUser,
  curentUser,
  selectedUsers,
  totalUsersProp,
  importAllUsers,
  handleChangeRole,
  handleChangeAnnounce,
  assignLicenses,
  handleBack,
  handleBulkSelect,
  handleBulkAnnounceAction,
  handleImportAllUsers,
  parentComponent,
  assignLicensesLoading = false,
}: IManageAssignLicensesProps): React.ReactElement | null => {
  const elementRefUserList = useRef<HTMLDivElement>(null)
  const elementRefEmail = useRef<HTMLDivElement>(null)

  const { formatMessage } = useIntl()
  const [ searchLoadingUsers, setSearchLoadingUsers ] = useState(false)
  const [ userList, setUserList ] = useState<IMicrosoftUser[]>([])
  const [ nextPage, setNextPage ] = useState<string | null>(null)
  const [ loadingMoreInfinite, setLoadingMoreInfinite ] = useState(false)
  const [ hasMore, setHasMore ] = useState(true)
  const [ isLoading, setIsLoading ] = useState(true)
  const [ userListHeight, setUserListHeight ] = useState(0)
  const [ moreSelectedUsersThanLicenses, setMoreSelectedUsersThanLicenses ] = useState(false)
  const [ showBulkUserAction, setShowBulkUserAction ] = useState(true)
  const [ avaliableLicenses, setAvaliableLicenses] = useState(0)
  const [ showMsLoginRequiredModal, setShowMsLoginRequiredModal] = useState<boolean>(false)
  const [ loadingUserList, setLoadingUserList] = useState<boolean>(false)
  const [ totalUsers, setTotalUsers ] = useState(totalUsersProp || 0)
  const [ isAssignLicensesDisabled, setIsAssignLicensesDisabled ] = useState(parentComponent === ParentComponentEnum.usersManageLicenses)

  useEffect(() => {
    if (userList.length === totalUsers) {
      setHasMore(false)
    } else {
      setHasMore(true)
    }
  }, [userList, totalUsers])

  useEffect(() => {
    if (elementRefUserList && elementRefUserList.current && elementRefUserList.current.clientHeight) {
      setUserListHeight(elementRefUserList.current.clientHeight)
    }
  }, [isLoading])

  useEffect(() => {
    if (numberOflicenses >= (selectedUsers.length + 1)) {
      setMoreSelectedUsersThanLicenses(false)
    } else {
      setMoreSelectedUsersThanLicenses(true)
    }
    setAvaliableLicenses(() => {
      const avaliable = numberOflicenses - (importAllUsers ? totalUsers : selectedUsers.length + 1)
      return avaliable > 0 ? avaliable : 0
    })
  }, [selectedUsers])

  const handleMsUsers = (users) => {
    return users.map(user => {
      user.id = `microsoft-${user.id}`

      if(user.id !== curentUser.id) {
        const selectedUser = find(selectedUsers, u => u.id === user.id)
        return {
          ...user,
          announce: selectedUser?.announce || 'chat',
          isAdmin: selectedUser?.isAdmin || false,
        }
      }
      return false
    }).filter(Boolean) as IMicrosoftUser[]
  }

  const getMsUsers = async (page?: string, newList = false) => {
    try {
      const tokens = msAuth.getTokens()
      const [totalUsers, users] = await Promise.all([
        await msAuth.getTotalUsers(tokens.accessToken),
        await msAuth.getUserListPagination(tokens.accessToken, page),
      ])
      setTotalUsers(totalUsers)
      setUserList((user) => {
        const userList = handleMsUsers(users.members)

        if(userList[0] && userList[0].id !== curentUser.id) {
          userList.unshift({
            ...curentUser,
            announce: 'chat',
            isAdmin: true,
          })
        }

        if (newList) {
          return unionBy(userList)
        }
        return unionBy(user.concat(userList), 'id')
      })
      setTimeout(() => {
        setSearchLoadingUsers(false)
        setLoadingMoreInfinite(false)
        setLoadingUserList(false)
      }, 500)
      if (users.nextPage) {
        setNextPage(users.nextPage)
      } else {
        setNextPage(null)
      }
      setIsLoading(false)
    } catch (error) {
      setShowMsLoginRequiredModal(true)
    }
  }

  const onSearch = async (search) => {
    try {
      setSearchLoadingUsers(true)
      setLoadingUserList(true)
      if (search.length > 0) {
        setNextPage(null)
        setShowBulkUserAction(false)
        const tokens = msAuth.getTokens()
        const msUsers = await msAuth.searchUserByEmailAndDisplayname(search, tokens.accessToken, null)
        setUserList(handleMsUsers(msUsers))
        setTimeout(() => {
          setSearchLoadingUsers(false)
          setLoadingUserList(false)
        }, 500)
      } else {
        await getMsUsers(undefined, true)
        setShowBulkUserAction(true)
      }
    } catch (error) {
      setShowMsLoginRequiredModal(true)
    }
  }

  const getMsTokens = () => {
    msAuth.signin(['user.readbasic.all', 'team.readbasic.all', 'Channel.ReadBasic.All'])
      .then(([msUser]) => {
        if (`microsoft-${msUser.id}` !== curentUser.id) {
          throw new Error('MS_NOT_SAME_ACCOUNT')
        }
        setShowMsLoginRequiredModal(false)
        return getMsUsers(undefined, true)
      })
      .catch(err => {
        if (err === 'access-denied-cancel') {
          const consentLink = `https://login.microsoftonline.com/${msAuth.getTenantId()}/adminconsent?client_id=${process.env.REACT_APP_MICROSOFT_CLIENT_ID}`
          antdNotification.error({
            key: err,
            message: formatMessage({ id: 'microsoft.adminConsentErrorTitle' }),
            description: (<>
              <Paragraph>{formatMessage({ id: 'microsoft.adminConsentErrorDescription1'})}</Paragraph>
              <Paragraph copyable={{ text: consentLink }}>
                <a
                  href={consentLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ wordBreak: 'break-word' }}
                >
                  {consentLink}
                </a>
              </Paragraph>
              <p>{formatMessage({ id: 'microsoft.adminConsentErrorDescription2'})}</p>
            </>),
            btn: (<Button onClick={() => {
              getMsTokens()
              antdNotification.close(err)
            }}>
              {formatMessage({ id: 'microsoft.adminConsentErrorButton'})}
            </Button>),
            duration: 0,
          })
        }
        setShowMsLoginRequiredModal(false)
      })
  }

  const handleInfiniteOnLoad = () => {
    if (userList.length === totalUsers) {
      return
    }
    setLoadingMoreInfinite(true)
    if (nextPage) {
      setTimeout(() => {
        getMsUsers(nextPage)
      }, 200)
    }
  }

  const handleChangeUserRole = (role: 'admin' | 'user', userId: string) => {
    setUserList(user => {
      return user.map(user => {
        if (user.id === userId) {
          user.isAdmin = role === 'admin' ? true : false
        }
        return user
      })
    })
    handleChangeRole(role, userId)
  }

  const handleChangeUserAnnounce = (announce, userId) => {
    setUserList(user => {
      return user.map(user => {
        if (user.id === userId) {
          user.announce = announce
        }
        return user
      })
    })
    handleChangeAnnounce(announce, userId)
  }

  const handleBulkSelectUser = () => {
    setIsAssignLicensesDisabled(false)
    if (totalUsers > numberOflicenses) {
      setMoreSelectedUsersThanLicenses(true)
    }
    if (handleBulkSelect) {
      handleBulkSelect(!importAllUsers, userList)
    }
  }

  const handleBulkAnnounce = (event: 'none' | 'email' | 'chat') => {
    if (handleBulkAnnounceAction) {
      handleBulkAnnounceAction(event)
    }
  }

  return (
    <div className="manage-assign-licenses">
      <div className="header">
        <div>
          <IntlMessages id="components.manageAssignLicenses.totalLicenses" values={{ numberOflicenses }} />
          <span style={{ marginLeft: 25 }}>
            <IntlMessages id="components.manageAssignLicenses.avaliableToAssign" values={{ avaliableLicenses }} />
          </span>
        </div>
        <Search
          displaySpinner={true}
          onLoad={searchLoadingUsers}
          onSearch={onSearch}
          placeholder={formatMessage({ id: 'users.search' })}
        />
      </div>
      <div className="errors">
        {moreSelectedUsersThanLicenses &&
          <Alert
            type="error"
            message={<IntlMessages id="components.manageAssignLicenses.moreUserThenLicenses" values={{ users: (selectedUsers.length + 1) - numberOflicenses }} />}
            showIcon
          />
        }
      </div>
      {isLoading ? <CircularProgress style={{ height: 'calc(100vh - 92px)' }} /> :
        <>
          <Card bordered={false} size="small" className="user-card user-card-header">
            <span
              className="check-box"
              onClick={handleBulkSelectUser}
            >
              {showBulkUserAction && <>{importAllUsers ? <CheckSquareOutlined /> : <MinusSquareOutlined />}</> }
            </span>
            <div className="name">
              <IntlMessages id="app.name" />
            </div>
            <div className="email">
              <IntlMessages id="app.email" />
            </div>
            <div className="select-role">
              <IntlMessages id="app.users.role" />
              <Tooltip placement="top" title={
                <IntlMessages id="components.manageAssignLicenses.roleTooltipInfo" values={{
                  // eslint-disable-next-line
                  helpDesk: (...chunks) => (
                    <a href="https://vacationtracker.crisp.help/en/" target="_blank" rel="noopener noreferrer">
                      {chunks}
                    </a>
                  ),
                }} />}>
                <InfoCircleOutlined style={{ marginLeft: 5 }} />
              </Tooltip>
            </div>
            <div className="select-announce">
              <IntlMessages id="components.manageAssignLicenses.sendWelcomeMessage" />
              {handleBulkAnnounceAction &&
                <Select onChange={handleBulkAnnounce} size="small"
                  placeholder={<IntlMessages id="components.manageAssignLicenses.placeholderBulkWelcomeMessage" />}>
                  <Select.Option key={'none'} value={'none'}>
                    <IntlMessages id="app.none" />
                  </Select.Option>
                  <Select.Option key={'chat'} value={'chat'}>
                    <IntlMessages id="components.manageAssignLicenses.microsoftTeamsMessage" />
                  </Select.Option>
                  <Select.Option key={'email'} value={'email'}>
                    <IntlMessages id="app.email" />
                  </Select.Option>
                </Select>
              }
            </div>
          </Card>
          <div ref={elementRefUserList} style={{ position: 'relative'}}>
            <div className={userList.length * 51 > userListHeight ? 'bottom-border-box' : ''}>
            </div>
            <div className="infinite-scroll-wrap">
              <InfiniteScroll
                initialLoad={false}
                loadMore={handleInfiniteOnLoad}
                hasMore={hasMore}
                useWindow={false}
                threshold={50}
              >
                <List
                  grid={{ gutter: 6, xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }}
                  dataSource={userList}
                  loading={loadingUserList}
                  renderItem={(user) => {
                    const findUser = find(selectedUsers, selectedUser => selectedUser.id === user.id)
                    const announce = findUser ? findUser.announce : user.announce
                    const isCurentUser = Boolean(user.id === curentUser.id)
                    let userRole = 'user'
                    if (findUser?.isAdmin || user.isAdmin) {
                      userRole = 'admin'
                    }
                    const emailRowWidth = elementRefEmail?.current?.clientWidth || 100
                    const email = (user.mail || user.userPrincipalName) as string
                    const emailTextWidth = getTextWidth(email, 'normal 14px NoirPro')
                    return (
                      <List.Item style={{ marginBottom: 10 }}>
                        <Card bordered={false} size="small" className="user-card">
                          <Checkbox
                            onClick={() => {
                              if (user.id !== curentUser.id) {
                                onSelectUser(user)
                                handleImportAllUsers && handleImportAllUsers(false)
                                setIsAssignLicensesDisabled(false)
                              }
                            }}
                            disabled={user.id === curentUser.id}
                            checked={isCurentUser || Boolean(findUser)}
                          />
                          <div className="name">
                            {user.displayName}
                          </div>
                          <div className="email" ref={elementRefEmail}>
                            <Tooltip title={emailTextWidth > emailRowWidth ? `${email}` : ''} placement="top" overlayStyle={{ maxWidth: '500px' }}>
                              {email}
                            </Tooltip>
                          </div>
                          <div className="select-role">
                            <Select
                              defaultValue={userRole}
                              value={userRole}
                              onChange={(event) => handleChangeUserRole(event as 'admin' | 'user', user.id)}
                              disabled={isCurentUser || Boolean(!findUser)}
                            >
                              <Select.Option key={'admin'} value={'admin'}>
                                <IntlMessages id="app.role.administrator" />
                              </Select.Option>
                              <Select.Option key={'user'} value={'user'}>
                                <IntlMessages id="app.user" />
                              </Select.Option>
                            </Select>
                          </div>
                          <div className="select-announce">
                            <Select
                              defaultValue={announce}
                              value={announce}
                              onChange={(event) => handleChangeUserAnnounce(event, user.id)}
                              disabled={isCurentUser || Boolean(!findUser)}
                            >
                              <Select.Option key={'none'} value={'none'}>
                                {findUser ?
                                  <IntlMessages id="app.none" /> :
                                  <IntlMessages id="app.select" />
                                }
                              </Select.Option>
                              <Select.Option key={'chat'} value={'chat'}>
                                {findUser || user.id == curentUser.id ?
                                  <IntlMessages id="components.manageAssignLicenses.microsoftTeamsMessage" /> :
                                  <IntlMessages id="app.select" />
                                }
                              </Select.Option>
                              <Select.Option key={'email'} value={'email'}>
                                {findUser ?
                                  <IntlMessages id="app.email" /> :
                                  <IntlMessages id="app.select" />
                                }
                              </Select.Option>
                            </Select>
                          </div>

                        </Card>
                      </List.Item>
                    )
                  }}
                >
                  {loadingMoreInfinite && hasMore && (
                    <div style={{ position: 'absolute', bottom: 40, width: '100%', textAlign: 'center' }}>
                      <Spin />
                    </div>
                  )}
                </List>
              </InfiniteScroll>
            </div>
          </div>
          <div className="footer" style={handleBack ? {} : { flexDirection: 'row-reverse' }}>
            {handleBack &&
              <Button onClick={handleBack}>
                <IntlMessages id="app.back" />
              </Button>
            }
            <Button
              type="primary"
              onClick={assignLicenses}
              loading={assignLicensesLoading}
              disabled={moreSelectedUsersThanLicenses || assignLicensesLoading || isAssignLicensesDisabled}
            >
              <IntlMessages id="components.manageAssignLicenses.assignLicenses" values={{ numberOfToAssignLicenses: selectedUsers.length + 1 }} />
            </Button>
          </div>
        </>
      }
      {showMsLoginRequiredModal &&
        <Modal
          title={formatMessage({ id: 'microsoft.universalLoginRequiredTitle' })}
          visible={showMsLoginRequiredModal}
          onOk={getMsTokens}
          onCancel={() => {
            setShowMsLoginRequiredModal(false)
            if (handleBack) {
              handleBack()
            }
          }}
        >
          <Paragraph><IntlMessages id="microsoft.universalLoginRequiredDescription" /></Paragraph>
        </Modal>
      }
    </div>
  )
}

export default ManageAssignLicenses