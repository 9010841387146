import React from 'react'
import { useIntl } from 'react-intl'
import { Form, Input } from 'antd'
import IntlMessages from '@vacationtracker/shared/components/utils/IntlMessages'

interface MFACodeInputProps {
  handleEnter?: () => void
}

const MFACodeInput = ({ handleEnter }: MFACodeInputProps) => {

  const { formatMessage } = useIntl()

  return (
    <Form.Item
      label={`${formatMessage({ id: 'app.code' })}:`}
      name="code"
      rules={[
        {
          required: true,
          message: <IntlMessages id="form.inputRequired"/>,
        },
        {
          pattern: /^[0-9]{6}$/,
          message: <IntlMessages id="form.inputSize" values={{ len:6 }}/>,
        },
      ]}
    >
      <Input
        onKeyUp={(e) => {
          if(e.key === 'Enter' && handleEnter){
            handleEnter()
          }
        }}
        inputMode='numeric'
        pattern='[0-9]*'
        autoComplete='one-time-code'
        maxLength={6}
        placeholder={`${formatMessage({ id: 'app.mfaEnterCode' })}`}
      />
    </Form.Item>
  )
}

export default MFACodeInput