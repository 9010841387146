import { IGetFormattedDaysAndHours } from '../types/display-in-days-hours'
import { roundTo2Decimals, roundTo4Decimals } from './calculation-shared'

export interface IComputeDaysAndHours {
  days: number
  hours: number
}

export function getDaysOrHours(value: number, hourlyLeaveAccounting: boolean, numberOfWorkingHoursPerDayParam?: number): number {
  const numberOfWorkingHoursPerDay = numberOfWorkingHoursPerDayParam || 8
  if (hourlyLeaveAccounting) {
    return value * numberOfWorkingHoursPerDay
  } else {
    return value
  }
}

export function setDaysOrHours(value: number, hourlyLeaveAccounting: boolean, numberOfWorkingHoursPerDayParam?: number): number {
  const numberOfWorkingHoursPerDay = numberOfWorkingHoursPerDayParam || 8
  if (hourlyLeaveAccounting) {
    return roundTo4Decimals(value / numberOfWorkingHoursPerDay)
  } else {
    return value
  }
}

export function formatDaysHoursValue(days: number, hasUnlimitedDays: boolean, hourlyLeaveAccounting: boolean, numberOfWorkingHoursPerDayParam?: number): string {
  const numberOfWorkingHoursPerDay = numberOfWorkingHoursPerDayParam || 8
  if (hasUnlimitedDays) {
    return '∞'
  } else if (hourlyLeaveAccounting) {
    return roundTo2Decimals(days * numberOfWorkingHoursPerDay).toString()
  } else {
    return roundTo2Decimals(days).toString()
  }
}

export function computeDaysAndHours (hours: number, numberOfWorkingHoursPerDay = 8): IComputeDaysAndHours {
  if (Math.sign(hours) === -1) {
    return {
      days: -Math.floor(Math.abs(hours) / numberOfWorkingHoursPerDay),
      hours: -Math.floor(Math.abs(hours) % numberOfWorkingHoursPerDay),
    }
  } else {
    return {
      days: Math.floor(hours / numberOfWorkingHoursPerDay),
      hours: Math.floor(hours % numberOfWorkingHoursPerDay),
    }
  }
}

export const getFormattedDaysAndHours = ({
  leave,
  formatMessage,
  displayFormat,
}: IGetFormattedDaysAndHours): string => {
  if (leave.days === 0 && leave.hours === 0) {
    return displayFormat === 'long'
      ? `0 ${formatMessage({ id: 'dashboard.hour' })}`
      : `0${formatMessage({ id: 'dashboard.hourSingle' })}`
  }

  const daysLong = leave.days !== 0
    ? `${leave.days} ${formatMessage({ id: 'dashboard.daysSmallCase' })}`
    : ''
  const hoursLong = leave.hours !== 0
    ? `${leave.hours} ${formatMessage({ id: 'dashboard.hour' })}`
    : ''
  const daysShort = leave.days !== 0
    ? `${leave.days}${formatMessage({ id: 'dashboard.daysSmallCaseSingle' })}`
    : ''
  const hoursShort = leave.hours !== 0
    ? `${leave.hours}${formatMessage({ id: 'dashboard.hourSingle' })}`
    : ''
  const justString = displayFormat === 'long'
    ? `${daysLong} ${hoursLong}`
    : `${daysShort} ${hoursShort}`

  // This replace removing whitespace from both sides of a string
  return justString.trim()
}
