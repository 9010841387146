import React, { useState } from 'react'
import { Modal, Form, Select } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import IntlMessages from '../../util/IntlMessages'

import { ITeamShort } from '@vacationtracker/shared/types/team'
import { IUserFilteredInfo } from '../../types/users'


interface IChangeTeamFormProps {
  onSave: (v) => void
  handleCancel: () => void
  visibleModal: boolean
  teams: ITeamShort[]
  selectedUser: IUserFilteredInfo | undefined
}

const ChangeTeamForm = ({ onSave, handleCancel, visibleModal, teams, selectedUser }: IChangeTeamFormProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const onFinish = () => {
    form
      .validateFields()
      .then(values => {
        setLoading(true)
        onSave(values.team)
      })
      .catch(info => {
        console.log('Validate Failed:', info)
      })
  }

  const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 15 },
  }

  return (
    <Modal
      title={<IntlMessages id="components.changeTeamForm.changeUserTeam" />}
      visible={visibleModal}
      closeIcon={<CloseCircleOutlined />}
      onCancel={handleCancel}
      confirmLoading={loading}
      okText={<IntlMessages id="components.modal.confirm" />}
      onOk={onFinish}
      cancelText={<IntlMessages id="app.cancel" />}
    >
      <Form
        key="ChangeTeamForm"
        form={form}
        name="ChangeTeamForm"
        onFinish={onFinish}
      >
        <p><IntlMessages id="components.changeTeamForm.changeTeamWarning" /></p>
        <Form.Item
          {...formItemLayout}
          name="team"
          label={<IntlMessages id="components.changeTeamForm.selectTeam" />}
          rules={[{ required: true, message: <IntlMessages id="components.changeTeamForm.selectTeamError" /> }]}
        >
          <Select>
            {teams.filter(team => team.id !== selectedUser?.team?.id).map(team => <Select.Option key={team.id} value={team.id}>{team.name}</Select.Option>)}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}
export default ChangeTeamForm
