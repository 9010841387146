import React from 'react'
import { format } from 'date-fns-tz'

import { getDateFnsLocale } from '@vacationtracker/shared/i18n'
import { getTimeZoneOrDefaultToUtc } from '@vacationtracker/shared/functions/timezone'

import IntlMessages from '../../util/IntlMessages'

import { LocaleEnum } from '@vacationtracker/shared/types/i18n'



interface IResetQuotas {
  status: string
  yearStartDay: number
  yearStartMonth: number
  locale: LocaleEnum
  locationTimezone: string
}

const ResetQuotas = ({ status, yearStartDay, yearStartMonth, locale, locationTimezone }: IResetQuotas): React.ReactElement => {
  if(status === 'FISCAL_YEAR') {
    const timeZone = locationTimezone || getTimeZoneOrDefaultToUtc()
    const currentYear = new Date().getFullYear()
    const monthIndex = yearStartMonth - 1
    const date = format(new Date(currentYear, monthIndex, yearStartDay), 'LLLL do', { timeZone, locale: getDateFnsLocale(locale) })
    return (<IntlMessages id="components.resetQuotas.fiscalYear" values={{ date }} />)
  } else {
    return (<IntlMessages id="users.employmentDate" />)
  }
}

export default ResetQuotas
