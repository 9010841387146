import React from 'react'
import { Modal } from 'antd'
import IntlMessages from '../../util/IntlMessages'

import { SubscriptionPlan } from '@vacationtracker/shared/types/company'
import { SubscriptionPeriod } from '@vacationtracker/shared/types/billing'

interface ISwitchPlanModal {
  openSiwtchPlanModal: boolean
  currentPlan: SubscriptionPlan
  cancelPlanChange: () => void
  switchPlan: () => void
  newPlan: SubscriptionPlan
  newPeriod: SubscriptionPeriod
  currentPeriod: SubscriptionPeriod
  isLegacyPlan: boolean
  endDatePeriod: string
}

export const SwitchPlanModal = ({
  openSiwtchPlanModal,
  currentPlan,
  cancelPlanChange,
  switchPlan,
  newPlan,
  newPeriod,
  isLegacyPlan,
  currentPeriod,
  endDatePeriod,
}: ISwitchPlanModal): React.ReactElement => {

  const getSwitchPlanMessage = () => {
    if (currentPlan === 'Core' && newPlan === 'Complete' && currentPeriod === 'monthly' && newPeriod === 'monthly') {
      return <IntlMessages id="components.switchPlanModal.switchCoreMonthlyToCompleteMonthly" values={{
        strong: (...chunks) => <strong>{chunks}</strong>,
        legacyPlan: (...chunks) => isLegacyPlan ? chunks : '',
      }}/>
    }

    if (currentPlan === newPlan && currentPeriod === 'monthly' && newPeriod === 'annual') {
      return <IntlMessages id="components.switchPlanModal.switchFromMonthlyToAnnually" values={{
        newPlan,
        strong: (...chunks) => <strong>{chunks}</strong>,
        legacyPlan: (...chunks) => isLegacyPlan ? chunks : '',
      }} />
    }

    if (currentPlan === 'Core' && newPlan === 'Complete' && currentPeriod === 'monthly' && newPeriod === 'annual') {
      return <IntlMessages id="components.switchPlanModal.switchFromCoreMonthlyToCompleteAnnual" values={{
        strong: (...chunks) => <strong>{chunks}</strong>,
        legacyPlan: (...chunks) => isLegacyPlan ? chunks : '',
      }}/>
    }

    if (currentPlan === 'Complete' && newPlan === 'Core' && currentPeriod === 'monthly' && newPeriod === 'monthly') {
      return <IntlMessages id="components.switchPlanModal.switchFromCompleteMonthlyToCoreMonthly" values={{
        strong: (...chunks) => <strong>{chunks}</strong>,
        endDatePeriod,
      }}/>
    }

    if (currentPlan === 'Complete' && newPlan === 'Core' && currentPeriod === 'monthly' && newPeriod === 'annual') {
      return <IntlMessages id="components.switchPlanModal.switchFromCompleteMonthlyToCoreAnnual" values={{
        strong: (...chunks) => <strong>{chunks}</strong>,
        endDatePeriod,
      }}/>
    }

    if (currentPlan === newPlan && currentPeriod === 'annual' && newPeriod === 'monthly') {
      return <IntlMessages id="components.switchPlanModal.switchFromAnnuallyToMonthly" values={{
        newPlan,
        endDatePeriod,
        strong: (...chunks) => <strong>{chunks}</strong>,
        legacyPlan: (...chunks) => isLegacyPlan ? chunks : '',
      }} />
    }

    if (currentPlan === 'Core' && newPlan === 'Complete' && currentPeriod === 'annual' && newPeriod === 'annual') {
      return <IntlMessages id="components.switchPlanModal.switchFromCoreAnnualToCompleteAnnual" values={{
        strong: (...chunks) => <strong>{chunks}</strong>,
        legacyPlan: (...chunks) => isLegacyPlan ? chunks : '',
      }}/>
    }

    if (currentPlan === 'Complete' && newPlan === 'Core' && currentPeriod === 'annual' && newPeriod === 'monthly') {
      return <IntlMessages id="components.switchPlanModal.switchFromCompleteAnnualToCoreMonthly" values={{
        strong: (...chunks) => <strong>{chunks}</strong>,
        endDatePeriod,
      }}/>
    }

    if (currentPlan === 'Complete' && newPlan === 'Core' && currentPeriod === 'annual' && newPeriod === 'annual') {
      return <IntlMessages id="components.switchPlanModal.switchFromCompleteAnnualToCoreAnnual" values={{
        strong: (...chunks) => <strong>{chunks}</strong>,
        endDatePeriod,
      }}/>
    }
  }

  return (
    <Modal
      title={<IntlMessages id="subscriptions.switchPlanTitle" values={{
        plan: newPlan,
        strong: (...chunks) => <strong>{chunks}</strong>,
      }} />}
      centered
      visible={openSiwtchPlanModal}
      onOk={() => switchPlan()}
      okText={<IntlMessages id="app.switch" />}
      onCancel={() => cancelPlanChange()}
      cancelText={<IntlMessages id="app.cancel" />}
    >
      {getSwitchPlanMessage()}
    </Modal>
  )
}

export default SwitchPlanModal
