import { ILeaveDay, IUserLeaves } from '@vacationtracker/shared/types/leave-request'
import {TableDataRow} from './types'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import _ from 'lodash'
import { IUserSlim } from '../../types/users'

dayjs.extend(isBetween)

export const findOverlappingLeaves = (userLeaves: TableDataRow[]): {
  overlappingPairs: TableDataRow[][]
} => {
  const groupedLeaves = _.groupBy(userLeaves, 'email')
  const overlappingPairs: any[] = []

  _.forEach(groupedLeaves, (leaves, email) => {
    // Sort leaves by dateFrom within each group for easier comparison
    const sortedLeaves = _.sortBy(leaves, (leave) =>
      dayjs(leave.dateFrom).unix()
    )

    for (let i = 0; i < sortedLeaves.length; i++) {
      for (let j = i + 1; j < sortedLeaves.length; j++) {
        const currentLeave = sortedLeaves[i]
        const nextLeave = sortedLeaves[j]
        const currentLeaveEnd = dayjs(currentLeave.dateTo)
        const nextLeaveStart = dayjs(nextLeave.dateFrom)

        // Check if the next leave starts before the current leave ends
        if (
          nextLeaveStart.isBefore(currentLeaveEnd) ||
          nextLeaveStart.isSame(currentLeaveEnd)
        ) {
          overlappingPairs.push([currentLeave, nextLeave])
        }
      }
    }
  })
  return {overlappingPairs}
}

export function getEarliestAndLatestDates(leaves: TableDataRow[]) {
  if (leaves.length === 0) {
    return {
      earliestDate: null,
      latestDate: null,
    }
  }

  let earliestDate
  let latestDate

  if (!leaves[0].dateFrom && !leaves[0].dateTo) {
    // If both dates are empty, return today and minus one year
    earliestDate = dayjs().subtract(1, 'year')
    latestDate = dayjs().add(1, 'year')
  } else if (!leaves[0].dateFrom) {
    // If dateFrom is empty, dateTo minus one year
    earliestDate = dayjs(leaves[0].dateTo).subtract(1, 'year')
    latestDate = dayjs(leaves[0].dateTo).add(1, 'year')
  } else if (!leaves[0].dateTo) {
    // If dateTo is empty, return dateFrom plus one year
    earliestDate = dayjs(leaves[0].dateFrom).subtract(1, 'year')
    latestDate = dayjs(leaves[0].dateFrom).add(1, 'year')
  } else {
    earliestDate = dayjs(leaves[0].dateFrom).subtract(1, 'year')
    latestDate = dayjs(leaves[0].dateTo).add(1, 'year')
  }

  leaves.forEach((leave) => {
    const fromDate = dayjs(leave.dateFrom)
    const toDate = dayjs(leave.dateTo)
    if (fromDate.isBefore(earliestDate)) {
      earliestDate = fromDate
    }
    if (toDate.isAfter(latestDate)) {
      latestDate = toDate
    }
  })

  return {
    earliestDate: earliestDate,
    latestDate: latestDate,
  }
}


export function findSameDateLeaves(
  users: IUserSlim[],
  userLeaves: IUserLeaves[],
  leaveToImport: TableDataRow
) {
  const sameDateLeaves: any[] = []
  const user = users.find(
    (u) => u.email.toLowerCase() === leaveToImport.email && leaveToImport.email.toLowerCase()
  )

  if (!user) {
    return sameDateLeaves // Return empty array if user not found
  }

  const userLeaveRequests = userLeaves.find( lr => lr.userId === user.id )

  userLeaveRequests?.leaves.forEach((lr) => {
    const leaveToImportStart = dayjs(leaveToImport.dateFrom)
    const leaveToImportEnd = dayjs(leaveToImport.dateTo)
    const currentLeaveStart = dayjs(lr.date)
    const currentLeaveEnd = dayjs(lr.date)

    if (
      leaveToImportStart.isSame(currentLeaveStart, 'day') ||
      leaveToImportEnd.isSame(currentLeaveEnd, 'day') ||
      leaveToImportStart.isBetween(currentLeaveStart, currentLeaveEnd) ||
      leaveToImportEnd.isBetween(currentLeaveStart, currentLeaveEnd)
    ) {
      sameDateLeaves.push(lr)
    }
  })

  if (sameDateLeaves.length) {
    const [leave] = sameDateLeaves

    if (leave.isPartDay && leaveToImport.isHalfDay) {
      return hasMoreThanFullDay(leave as ILeaveDay, leaveToImport)
    } else {
      return { message: 'You have already requested leave for that day', level: 'error'}
    }
  }
  return false
}

export function hasMoreThanFullDay(sameDayLeave: ILeaveDay, newLeave: TableDataRow) {
  if (!sameDayLeave.isPartDay) {
    return false
  }

  const { isHalfDay, hourFrom, hourTo } = newLeave

  let newLeaveHours: number
  if (isHalfDay && typeof Number(hourFrom) === 'number' && typeof Number(hourTo) === 'number') {
    newLeaveHours = Number(hourTo) - Number(hourFrom)
  } else {
    newLeaveHours = 8
  }

  if (newLeaveHours > 8 || newLeaveHours < 1) {
    return true
  }

  const leaveHours = sameDayLeave.hours
  // new leave and old leave can't be more than 8 hours
  if (leaveHours + newLeaveHours > 8) {
    return { message: `You can't have more than 8 hours per day. You have already requested ${leaveHours} hours for that day`, level: 'error' }
  }
}


