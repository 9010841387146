import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Checkbox, Col, Form, Modal, Row } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { cloneDeep } from 'lodash'
import { IUpdateWorkweekFormProps } from './types'

import IntlMessages from '../../util/IntlMessages'
import { WorkWeek } from '../../util/WorkWeek'

const UpdateWorkweekForm = ({
  workWeek,
  firstDayOfTheWeek = 1,
  handleSave,
  handleCancel,
  visible,
}: IUpdateWorkweekFormProps) => {
  const [form] = Form.useForm()

  const workWeekCopy = cloneDeep(WorkWeek)

  const { formatMessage } = useIntl()

  const beginningOfTheWeek = workWeekCopy.splice(firstDayOfTheWeek)
  const days = [
    ...beginningOfTheWeek,
    ...workWeekCopy,
  ]

  const defaultValue = {
    workWeek,
  }

  const [confirmLoading, setConfirmLoading] = useState(false)

  const onFinish = () => {
    const values = form.getFieldsValue()
    if (values.workWeek.length > 0) {
      setConfirmLoading(true)
      handleSave(values.workWeek)
      setConfirmLoading(false)
    }
  }

  const formItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
  }

  return (
    <Modal
      title={<IntlMessages id="components.updateWorkweekForm.title" />}
      visible={visible}
      closeIcon={<CloseCircleOutlined />}
      confirmLoading={confirmLoading}
      okText={<IntlMessages id="app.update" />}
      onOk={onFinish}
      cancelText={<IntlMessages id="app.cancel" />}
      onCancel={handleCancel}
      test-id="update-work-week-modal"
    >
      <Form
        key="updateWorkweekForm"
        form={form}
        name="updateWorkweekForm"
        onFinish={onFinish}
        initialValues={defaultValue}
        data-testid="update-workweek-form"
      >
        <Form.Item
          {...formItemLayout}
          name="workWeek"
          label={`${formatMessage({ id: 'app.workweek' })}:`}
          rules={[{ required: true, message: <IntlMessages id="components.updateWorkweekForm.form.error" /> }]}
        >
          <Checkbox.Group data-testid="checkboxes">
            <Row>
              {
                days.map((item, index) =>
                  <Col span={24} key={item.value}>
                    <Checkbox value={item.value} key={item.value} data-testid={`checkbox-${index}`}>{item.label}</Checkbox>
                  </Col>
                )
              }
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Modal>
  )
}
export default UpdateWorkweekForm
