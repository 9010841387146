import React, { useState, useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Alert, Descriptions, notification, Typography, Input, Button } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import qs from 'qs'
import dayjs from 'dayjs'
import { API, graphqlOperation } from 'aws-amplify'
import { getLeaveRequestById, getToilRequestById } from '../../../graphql/custom-queries'

import { wait } from '@vacationtracker/shared/functions/wait'
import { useAppSelector } from '../../../store/hooks'
import { selectAuthUserSlice } from '../../../store/auth-user-slice'
import { selectAuthCompanySlice } from '../../../store/auth-company-slice'
import { selectLocaleSlice } from '../../../store/locale-slice'
import { notificationStore } from '../../../context/notificationsContext/store'
import { track } from '../../../services/analytics/analytics'
import { convertHourFormats } from '@vacationtracker/shared/functions/convert-between-hour-formats'

import IntlMessages from '../../../util/IntlMessages'
import CircularProgress from '../../../components/circular-progress'
import { LeaveRequestAlertStatus } from '../../../components/leave-request-alert-status'
import FormattedDate from '@vacationtracker/shared/components/formatted-date'
import { DisplayLeaveInDaysAndHours } from '@vacationtracker/shared/components/display-leave-in-days-and-hours'

import { IGetLeaveRequestByIdData, IGetLeaveRequestById, IGetToilRequestById, IGetToilRequestByIdData } from '../../../types/custom-queries'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'
import { isToilLeave } from '@vacationtracker/shared/functions/is-toil-leave-request'
import { lowerCase } from 'lodash'

const { Title, Paragraph } = Typography
const { TextArea } = Input

interface ILeaveRequestsPage {
  match: {
    params: {
      leaveRequestId: string
    }
  }
  location: {
    search: string
  }
  history: {
    push: Function
  }
}

const LeaveRequestsPage = ({ match, location, history }: ILeaveRequestsPage): React.ReactElement => {
  const now = new Date()
  const { locale } = useAppSelector(selectLocaleSlice)
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const { formatMessage } = useIntl()

  const [leaveRequest, setLeaveRequest] = useState<IGetLeaveRequestById>()
  const [toilRequest, setToilRequest] = useState<IGetToilRequestById>()
  const [isLoading, setIsLoading] = useState(true)
  const [denyWithReason, setDenyWithReason] = useState(false)
  const [actionWrong, setActionWrong] = useState(false)
  const [resend, setResend] = useState<boolean>(false)
  const [leaveRequestIdWrong, setLeaveRequestIdWrong] = useState(false)
  const [inProgressLeaveRequestAction, setInProgressLeaveRequestAction] = useState(false)
  const [inProgressDenyWithReason, setInProgressDenyWithReason] = useState(false)
  const [denyReason, setDenyReason] = useState<string>()
  const [currentLeaveRequestStatus, setCurrentLeaveRequestStatus] = useState<string>()
  const [hourlyLeaveAccounting] = useState<boolean>(Boolean(authCompany?.hourlyLeaveAccounting))

  useEffect(() => {
    const leaveId = match.params.leaveRequestId
    if (leaveId) {
      isToilLeave(leaveId) ? getToilRequest(leaveId) : getLeaveRequest(leaveId)
    } else {
      setLeaveRequestIdWrong(true)
    }
  }, [match.params.leaveRequestId, actionNotifications])

  useEffect(() => {
    if (inProgressLeaveRequestAction) {
      setDenyWithReason(false)
      return
    }
    if (leaveRequest && !leaveRequest.autoApproved && !leaveRequest.approver && leaveRequest.status === 'OPEN') {
      const action = qs.parse(location.search, { ignoreQueryPrefix: true }).action as string
      const actorId = qs.parse(location.search, { ignoreQueryPrefix: true }).actorId as string
      handleLeaveRequestAction(action, actorId)
      return
    }
    handleResendLeaveRequestAction(leaveRequest as IGetLeaveRequestById)
    if (leaveRequest && leaveRequest.status !== 'OPEN') {
      setDenyWithReason(false)
      setIsLoading(false)
    }
  }, [leaveRequest])

  useEffect(() => {
    if (inProgressLeaveRequestAction) {
      setDenyWithReason(false)
      return
    }
    if (toilRequest && !toilRequest.approver && toilRequest.status === 'OPEN') {
      const action = qs.parse(location.search, { ignoreQueryPrefix: true }).action as string
      const actorId = qs.parse(location.search, { ignoreQueryPrefix: true }).actorId as string
      handleLeaveRequestAction(action, actorId)
      return
    }
    handleResendLeaveRequestAction(toilRequest as IGetToilRequestById)
    if (toilRequest && toilRequest.status !== 'OPEN') {
      setDenyWithReason(false)
      setIsLoading(false)
    }
  }, [toilRequest])

  const handleResendLeaveRequestAction = async(leaveRequest: IGetLeaveRequestById | IGetToilRequestById) => {
    if (leaveRequest && leaveRequest.status === 'EXPIRED') {
      const action = qs.parse(location.search, { ignoreQueryPrefix: true }).action as string
      const isToilRequest = isToilLeave(leaveRequest.id)
      const additionalCondition = isToilRequest ? true : leaveRequest.startDate > now.toISOString()
      const type = isToilRequest ? 'TOIL' : 'LEAVE'
      if (action === 'resend' && leaveRequest.status === 'EXPIRED' && additionalCondition) {
        setResend(true)
        const data = {
          startDate: leaveRequest.startDate,
          endDate: leaveRequest.endDate,
          reason: leaveRequest.reason,
          isPartDay: leaveRequest.isPartDay,
          partDayStartHour: leaveRequest.isPartDay ? leaveRequest.partDayStartHour : undefined,
          partDayEndHour: leaveRequest.isPartDay ? leaveRequest.partDayEndHour : undefined,
          leaveTypeId: leaveRequest.leaveType.id,
        }
        if (isToilRequest) {
          data['resentRequestId'] = leaveRequest.id
        } else {
          data['resentLeaveRequestId'] = leaveRequest.id
        }
        let response
        try {
          response = await API.post('CoreEvent', `/core/${lowerCase(type)}-request-validate`, {
            body: {
              eventType: `${type}_REQUEST_CREATED`,
              eventGroup: `USER_${type}_REQUEST`,
              ...data,
              userId: leaveRequest.user.id,
            },
          })

          response = await API.post('CoreEvent', '/core/event', {
            body: {
              eventType: `${type}_REQUEST_CREATED`,
              eventGroup: `USER_${type}_REQUEST`,
              ...data,
              userId: leaveRequest.user.id,
            },
          })
          track(`${type}_REQUEST_RESENT`, {
            companyId: authCompany?.id,
            userId: leaveRequest.user.id,
            leaveRequestId: leaveRequest.id,
          })
          notification.open({
            key: response.correlationId,
            message: formatMessage({ id: isToilRequest ? 'components.toil.requestToilProgress' : 'requestLeave.inProgress' }),
            icon: (<LoadingOutlined />),
            duration: 0,
          })
          setActionNotifications([ ...actionNotifications, response.correlationId ])
          history.push('/app/dashboard')
        } catch (error) {
          if (error.response?.data?.error || error.response.data.message) {
            notification.error({
              message: formatMessage({ id: 'error.leaveSubmitError' }),
              description: formatMessage({ id: error.response.data.message || error.response?.data?.error }),
              duration: 0,
            })
          } else {
            const description = response?.correlationId ? formatMessage({ id: 'app.correlationIdError' }, { correlationId: response.correlationId }) : JSON.stringify(error)
            notification.error({
              message: formatMessage({ id: 'error.leaveSubmitError' }),
              description,
              duration: 0,
            })
          }
        }
      }
    }
  }

  const handleLeaveRequestAction = async (action: string, actorId?: string) => {
    if (action === 'approve' || action === 'deny') {
      setIsLoading(false)
      onUpdateLeaveRequest(leaveRequest ?? toilRequest, action === 'approve' ? true : false)
      return
    }
    if (action === 'deny-with-reason') {
      setIsLoading(false)
      setDenyWithReason(true)
      return
    }
    if (action === 'snooze' && (leaveRequest || toilRequest) && actorId === authUser.id) {
      setIsLoading(false)
      const body = { leaveRequestId: leaveRequest ? leaveRequest.id : toilRequest?.id }
      await API.post('CoreEvent', '/core/snooze-reminders', { body } )
      notification.success({
        message: formatMessage({ id: 'bot.message.reminderSnoozed' }),
        description: formatMessage({ id: 'bot.message.reminderSnoozed' }),
        duration: 10,
      })
      return
    }

    setActionWrong(true)
    setIsLoading(false)
    await wait(7000)
    history.push('/app/dashboard')
    return await Promise.resolve()
  }

  const getLeaveRequest = async (leaveRequestId: string) => {
    console.log('leaveRequestId', leaveRequestId)
    const leaveRequestResponse = await API.graphql(graphqlOperation(getLeaveRequestById, { leaveRequestId })) as IGetLeaveRequestByIdData

    if (!leaveRequestResponse.data.getLeaveRequest) {
      setLeaveRequestIdWrong(true)
      setIsLoading(false)
      await wait(7000)
      history.push('/app/dashboard')
      return
    }

    setCurrentLeaveRequestStatus(leaveRequestResponse.data.getLeaveRequest.status)
    const leaveDaysForUser = leaveRequestResponse.data.getLeaveRequest.user.leaveDays
    const leaveTypeId = leaveRequestResponse.data.getLeaveRequest.leaveType.id
    const currentYearIndex = leaveDaysForUser.findIndex(year => now.toISOString() > year.yearStart && now.toISOString() < year.yearEnd)
    const currentYearDays = leaveDaysForUser[currentYearIndex].leaveTypes.find(lt => lt.leaveTypeId === leaveTypeId)
    const nextYearDays = leaveDaysForUser[currentYearIndex + 1].leaveTypes.find(lt => lt.leaveTypeId === leaveTypeId)

    leaveRequestResponse.data.getLeaveRequest.user = {
      ...leaveRequestResponse.data.getLeaveRequest.user,
      currentYearDays,
      nextYearDays,
    }

    setLeaveRequest(leaveRequestResponse.data.getLeaveRequest)
  }

  const getToilRequest = async (leaveRequestId: string) => {
    const response = await API.graphql(graphqlOperation(getToilRequestById, { leaveRequestId })) as IGetToilRequestByIdData

    if (!response.data.getToilRequest) {
      setLeaveRequestIdWrong(true)
      setIsLoading(false)
      await wait(7000)
      history.push('/app/dashboard')
      return
    }

    setCurrentLeaveRequestStatus(response.data.getToilRequest.status)
    const leaveDaysForUser = response.data.getToilRequest.user.leaveDays
    const leaveTypeId = response.data.getToilRequest.leaveType.id
    const currentYearIndex = leaveDaysForUser.findIndex(year => now.toISOString() > year.yearStart && now.toISOString() < year.yearEnd)
    const currentYearDays = leaveDaysForUser[currentYearIndex].leaveTypes.find(lt => lt.leaveTypeId === leaveTypeId)
    const nextYearDays = leaveDaysForUser[currentYearIndex + 1].leaveTypes.find(lt => lt.leaveTypeId === leaveTypeId)

    response.data.getToilRequest.user = {
      ...response.data.getToilRequest.user,
      currentYearDays,
      nextYearDays,
    }

    setToilRequest(response.data.getToilRequest)
  }

  const onUpdateLeaveRequest = async (leave, leaveStatus, statusReason?: string) => {
    try {
      const leavePart = isToilLeave(leave?.id as string) ? 'TOIL' : 'LEAVE'
      const transPart = isToilLeave(leave?.id as string) ? 'components.toil' : 'user'
      const response = await API.post('CoreEvent', '/core/event', {
        body: {
          eventType: leaveStatus ? `${leavePart}_REQUEST_APPROVED` : `${leavePart}_REQUEST_DENIED`,
          eventGroup: `USER_${leavePart}_REQUEST`,
          userId: leave.user.id,
          statusReason,
          ... isToilLeave(leave?.id) ? {toilRequestId: leave.id} : {leaveRequestId: leave.id},
        },
      })
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: leaveStatus ? `${transPart}.approveInProgress` : `${transPart}.denyInProgress` }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setInProgressLeaveRequestAction(true)
      setActionNotifications([ ...actionNotifications, response.correlationId ])
    } catch (error) {
      const errorMessage = typeof error === 'object' ? (
        error.error ? error.error : JSON.stringify(error, null, 2)
      ) : (
        typeof error === 'string' ? error : error.toString()
      )

      notification.error({
        message: formatMessage({ id: 'error.somethingWentWrong' }),
        description: <div>
          <Paragraph>{ formatMessage({ id: 'connect.platformErrorDescription1' }) }</Paragraph>
          <Paragraph keyboard copyable>{ errorMessage }</Paragraph>
          <Paragraph>{ formatMessage({ id: 'connect.platformErrorDescription2' }, { platform: 'Slack' }) }</Paragraph>
        </div>,
        duration: 0,
      })
    }
  }

  const handleDenyWithReason = () => {
    setInProgressDenyWithReason(true)
    onUpdateLeaveRequest(leaveRequest ?? toilRequest, false, denyReason)
  }

  const onChangeDenyReason = (e) => {
    setDenyReason(e.target.value)
  }

  const backToDashboard = () => {
    return history.push('/app/dashboard')
  }

  const columnsStartEndDate = (leaveRequest: IGetLeaveRequestById | IGetToilRequestById, locale: LocaleEnum) => {
    let formattedDate = <>
      <FormattedDate value={leaveRequest.startDate} format="MMMM Do YYYY." locale={locale} /> -
      <FormattedDate value={leaveRequest.endDate} format="MMMM Do YYYY." locale={locale} />
    </>
    if (dayjs(leaveRequest.startDate).format('YYYY-MM-DD') === dayjs(leaveRequest.endDate as string).format('YYYY-MM-DD') && leaveRequest.isPartDay) {
      const startHour = convertHourFormats(authUser.hourFormat as HourFormatEnum, leaveRequest.partDayStartHour)?.value
      const startHourAmOrPm = convertHourFormats(authUser.hourFormat as HourFormatEnum, leaveRequest.partDayStartHour)?.amOrPm
      const endHour = convertHourFormats(authUser.hourFormat as HourFormatEnum, leaveRequest.partDayEndHour)?.value
      const endHourAmOrPm = convertHourFormats(authUser.hourFormat as HourFormatEnum, leaveRequest.partDayEndHour)?.amOrPm
      formattedDate = <>
        <FormattedDate value={leaveRequest.startDate} format="MMMM Do YYYY." locale={locale} />
        ({startHour}{startHourAmOrPm} - {endHour}{endHourAmOrPm})
      </>
    }
    if (dayjs(leaveRequest.startDate).format('YYYY-MM-DD') === dayjs(leaveRequest.endDate as string).format('YYYY-MM-DD') && !leaveRequest.isPartDay) {
      formattedDate = <FormattedDate value={leaveRequest.startDate} format="MMMM Do YYYY." locale={locale} />
    }

    return formattedDate
  }

  return (
    <div className='main-content'>
      <div className="main-content-body">
        {isLoading ?
          <CircularProgress /> :
          <>
            {leaveRequestIdWrong &&
              <Alert
                message={<IntlMessages id="leaveRequests.wrongLeaveRequestId" />}
                type="error"
                showIcon
              />
            }
            {actionWrong &&
              <Alert
                message={<IntlMessages id="leaveRequests.wrongAction" />}
                type="error"
                showIcon
              />
            }
            {leaveRequest && !actionWrong && !leaveRequestIdWrong &&
              <>
                <LeaveRequestAlertStatus
                  approverId={leaveRequest?.approver?.id}
                  approverName={leaveRequest?.approver?.name}
                  authUserId={authUser?.id}
                  currentLeaveRequestStatus={currentLeaveRequestStatus}
                  leaveRequestStatus={leaveRequest.status}
                  leaveRequestName={leaveRequest?.user.name}
                  resend={resend}
                />
                <Title level={4} style={{ marginTop: 30, marginBottom: 30 }}><IntlMessages id="leaveRequests.requestSummary" /></Title>
                <Paragraph style={{ marginBottom: 20 }}>
                  <IntlMessages id="leaveRequests.leaveRequestInfoTitle" values={{
                    name: leaveRequest.user.name,
                    // eslint-disable-next-line
                    strong: (...chunks) => (
                      <strong>{chunks}</strong>
                    ),
                  }} />
                </Paragraph>
                <Descriptions layout="vertical" column={2}>
                  <Descriptions.Item
                    labelStyle={{ fontWeight: 600 }}
                    label={<IntlMessages id="app.leaveType" />}>
                    {leaveRequest.leaveType.name}
                  </Descriptions.Item>
                  <Descriptions.Item
                    labelStyle={{ fontWeight: 600 }}
                    label={<IntlMessages id="leaveRequests.dates" values={{ plural: leaveRequest.workingDays > 1 ? 's' : '' }} />}
                  >
                    {columnsStartEndDate(leaveRequest, locale.locale as LocaleEnum)}
                  </Descriptions.Item>
                  {leaveRequest.totalThisYear > 0 &&
                    <>
                      <Descriptions.Item
                        labelStyle={{ fontWeight: 600 }}
                        label={<IntlMessages id={hourlyLeaveAccounting ? 'leaveRequests.hoursRequestedForCurrentYear' : 'leaveRequests.daysRequestedForCurrentYear'} />}
                      >
                        <DisplayLeaveInDaysAndHours value={leaveRequest.totalThisYear} />
                      </Descriptions.Item>
                      <Descriptions.Item
                        labelStyle={{ fontWeight: 600 }}
                        label={<IntlMessages id={hourlyLeaveAccounting ? 'leaveRequests.hoursRemainingForCurrentYear' : 'leaveRequests.daysRemainingForCurrentYear'} />}
                      >
                        {leaveRequest.user.currentYearDays?.hasUnlimitedDays
                          ? '∞'
                          : <DisplayLeaveInDaysAndHours value={ leaveRequest.user.currentYearDays?.currentDays ?? 0} />
                        }
                      </Descriptions.Item>
                    </>
                  }
                  {leaveRequest.totalNextYear > 0 &&
                    <>
                      <Descriptions.Item
                        labelStyle={{ fontWeight: 600 }}
                        label={<IntlMessages id={hourlyLeaveAccounting ? 'leaveRequests.hoursRequestedForNextYear' : 'leaveRequests.daysRequestedForNextYear'} />}
                      >
                        <DisplayLeaveInDaysAndHours value={leaveRequest.totalNextYear} />
                      </Descriptions.Item>
                      <Descriptions.Item
                        labelStyle={{ fontWeight: 600 }}
                        label={<IntlMessages id={hourlyLeaveAccounting ? 'leaveRequests.hoursRemainingForNextYear' : 'leaveRequests.daysRemainingForNextYear'} />}
                      >
                        {leaveRequest.user.nextYearDays?.hasUnlimitedDays
                          ? '∞'
                          : <DisplayLeaveInDaysAndHours value={ leaveRequest.user.nextYearDays?.currentDays ?? 0} />
                        }
                      </Descriptions.Item>
                    </>
                  }
                  {leaveRequest.reason &&
                    <Descriptions.Item
                      labelStyle={{ fontWeight: 600 }}
                      span={2}
                      label={<IntlMessages id="app.reason" />}
                    >
                      {leaveRequest.reason}
                    </Descriptions.Item>
                  }
                  {denyWithReason &&
                    <>
                      <Descriptions.Item
                        labelStyle={{ fontWeight: 600 }}
                        span={2}
                        label={<IntlMessages id="leaveRequests.reasonForDenying" />}
                      >
                        <TextArea rows={4} onChange={onChangeDenyReason} disabled={inProgressDenyWithReason} />
                      </Descriptions.Item>
                      <Descriptions.Item
                        labelStyle={{ fontWeight: 600 }}
                        span={2}
                      >
                        <Button type="primary" danger loading={inProgressDenyWithReason} onClick={handleDenyWithReason} >
                          <IntlMessages id="app.denyWithReason" />
                        </Button>
                        <Button type="link" onClick={backToDashboard} disabled={inProgressDenyWithReason}>
                          <IntlMessages id="leaveRequests.cancelThisAction" />
                        </Button>
                      </Descriptions.Item>
                    </>
                  }
                </Descriptions>
              </>
            }
            {toilRequest && !actionWrong && !leaveRequestIdWrong &&
              <>
                <LeaveRequestAlertStatus
                  approverId={toilRequest?.approver?.id}
                  approverName={toilRequest?.approver?.name}
                  authUserId={authUser?.id}
                  currentLeaveRequestStatus={currentLeaveRequestStatus}
                  leaveRequestStatus={toilRequest.status}
                  leaveRequestName={toilRequest?.user.name}
                  resend={resend}
                />
                <Title level={4} style={{ marginTop: 30, marginBottom: 30 }}><IntlMessages id="leaveRequests.requestSummary" /></Title>
                <Paragraph style={{ marginBottom: 20 }}>
                  <IntlMessages id="leaveRequests.leaveRequestInfoTitleToil" values={{
                    name: toilRequest.user.name,
                    // eslint-disable-next-line
                    strong: (...chunks) => (
                      <strong>{chunks}</strong>
                    ),
                  }} />
                </Paragraph>
                <Descriptions layout="vertical" column={2}>
                  <Descriptions.Item
                    labelStyle={{ fontWeight: 600 }}
                    label={<IntlMessages id="app.leaveType" />}>
                    <IntlMessages id="app.toil" /> - {toilRequest.leaveType.name}
                  </Descriptions.Item>
                  <Descriptions.Item
                    labelStyle={{ fontWeight: 600 }}
                    label={<IntlMessages id="leaveRequests.dates" values={{ plural: toilRequest.workingDays > 1 ? 's' : '' }} />}
                  >
                    {columnsStartEndDate(toilRequest, locale.locale as LocaleEnum)}
                  </Descriptions.Item>
                  {toilRequest.reason &&
                    <Descriptions.Item
                      labelStyle={{ fontWeight: 600 }}
                      span={2}
                      label={<IntlMessages id="app.reason" />}
                    >
                      {toilRequest.reason}
                    </Descriptions.Item>
                  }
                  {denyWithReason &&
                    <>
                      <Descriptions.Item
                        labelStyle={{ fontWeight: 600 }}
                        span={2}
                        label={<IntlMessages id="leaveRequests.reasonForDenying" />}
                      >
                        <TextArea rows={4} onChange={onChangeDenyReason} disabled={inProgressDenyWithReason} />
                      </Descriptions.Item>
                      <Descriptions.Item
                        labelStyle={{ fontWeight: 600 }}
                        span={2}
                      >
                        <Button type="primary" danger loading={inProgressDenyWithReason} onClick={handleDenyWithReason} >
                          <IntlMessages id="app.denyWithReason" />
                        </Button>
                        <Button type="link" onClick={backToDashboard} disabled={inProgressDenyWithReason}>
                          <IntlMessages id="leaveRequests.cancelThisAction" />
                        </Button>
                      </Descriptions.Item>
                    </>
                  }
                </Descriptions>
              </>
            }
          </>
        }
      </div>
    </div>
  )
}

export default LeaveRequestsPage
