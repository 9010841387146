import React, { useEffect, useState } from 'react'
import { Col, Form, FormInstance, Input, Row, Select, Typography } from 'antd'
import IntlMessages from '../../util/IntlMessages'

import { ISurvey, IQuestionOption } from '@vacationtracker/shared/data/survey'

const { Option } = Select
const { Title } = Typography

interface ISurveyProps {
  survey: ISurvey
  form: FormInstance
}

const SurveyFormPart = ({ survey, form }: ISurveyProps): React.ReactElement => {
  
  const [ selectedOption, setSelectedOption ] = useState<IQuestionOption|undefined>()
  const [ selectedSubOption, setSelectedSubOption ] = useState<IQuestionOption|undefined>()

  useEffect(() => {
    const createCompany = JSON.parse(localStorage.getItem('vtCreateCompany') || '{}')
    if(createCompany && createCompany.surveyQuestionOption) {
      setSelectedOption(survey.questions[0].options?.find(option => option.id === createCompany.surveyQuestionOption))
      form.setFieldsValue({ ['surveyQuestionOption']: createCompany.surveyQuestionOption })
    }
    if(createCompany && createCompany.surveyQuestionSuboption) {
      const option = survey.questions[0].options?.find(option => option.id === createCompany.surveyQuestionOption)
      setSelectedSubOption(option?.subQuestion?.options?.find(option => option.id === createCompany.surveyQuestionSuboption))
      form.setFieldsValue({ ['surveyQuestionSuboption']: createCompany.surveyQuestionSuboption })
    }
    if(createCompany && createCompany[`surveyQuestionSpecifyText-${createCompany.surveyQuestionOption}`]) {
      form.setFieldsValue({ [`surveyQuestionSpecifyText-${createCompany.surveyQuestionOption}`]: createCompany[`surveyQuestionSpecifyText-${createCompany.surveyQuestionOption}`] })
    }
    if(createCompany && createCompany[`surveyQuestionSpecifyText-${createCompany.surveyQuestionSuboption}`]) {
      form.setFieldsValue({ 
        [`surveyQuestionSpecifyText-${createCompany.surveyQuestionSuboption}`]: createCompany[`surveyQuestionSpecifyText-${createCompany.surveyQuestionSuboption}`], 
      })
    }
  }, [])

  const onSelectOption = (optionId: string) => {
    setSelectedSubOption(undefined)
    const questionIndex = optionId.split('-')[0] as unknown as number
    const newOption = survey.questions[questionIndex - 1].options?.find(option => option.id === optionId)
    setSelectedOption(newOption)
    form.setFieldsValue({ ['surveyQuestionOption']: newOption?.id })
  }

  const onSelectSubOption = (optionId: string) => {
    const newSubOption = selectedOption?.subQuestion?.options?.find(option => option.id === optionId)
    setSelectedSubOption(newSubOption)
    form.setFieldsValue({ ['surveyQuestionSuboption']: newSubOption?.id })
  }

  const textArea = (id: string, name: string, defaultLabel?: boolean): JSX.Element => {
    return (
      <Row>
        <Col xs={24} sm={24} xl={24}>
          <Form.Item
            labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}
            className='wrap-label'
            label={<IntlMessages id={defaultLabel ? 'surveyAboutUs.textarea' : id} />}
            name={name}
          >
            <Input className="data-hj-allow" data-qa='option-text'/>
          </Form.Item>
        </Col>
      </Row>
    )
  }

  return (
    <>
      {survey.questions.map((question, index) => (
        <div key={question.id}>
          <Title style={{ marginBottom: 15, marginTop: 15 }} level={5}>
            <IntlMessages id={`${survey.id}.Question${index+1}`} />
          </Title>
          <Form.Item
            key={question.id}
            className='wrap-label'
            label={<IntlMessages id="app.select" />}
            name='surveyQuestionOption'
          >
            <Select onChange={onSelectOption} className="data-hj-allow">
              {question.options?.map((option) => (
                <Option value={option.id} key={option.name}>
                  <IntlMessages id={`${survey.id}.OptionQuestion${option.id}`} />
                </Option>
              ))}
            </Select>
          </Form.Item>
          {selectedOption && selectedOption.subQuestion && selectedOption.subQuestion.options && (
            <Form.Item
              className='wrap-label'
              label={<IntlMessages id="app.select" />}
              name="surveyQuestionSuboption"
            >
              <Select onChange={onSelectSubOption} className="data-hj-allow">
                {selectedOption.subQuestion.options
                  .map((option) => (
                    <Option value={option.id} key={option.id}>
                      <IntlMessages id={`${survey.id}.OptionQuestion${option.id}`} />
                      {option.subQuestion && (
                        <Option value={option.subQuestion.id} key={option.subQuestion.name}>
                          <IntlMessages id={`${survey.id}.OptionQuestion${option.id}`} />
                        </Option>
                      )}
                    </Option>
                  ))
                }
              </Select>
            </Form.Item>
          )}
          {selectedSubOption && selectedSubOption.subQuestion && selectedSubOption.subQuestion.textarea && (
            textArea(`${survey.id}.OptionQuestion${selectedSubOption.subQuestion.id}`, `surveyQuestionSpecifyText-${selectedSubOption.id}`, selectedSubOption.defaultLabel)
          )}
          {selectedSubOption && !selectedSubOption.subQuestion && selectedSubOption.textarea && (
            textArea(`${survey.id}.OptionQuestion${selectedSubOption.id}`, `surveyQuestionSpecifyText-${selectedSubOption.id}`, selectedSubOption.defaultLabel)
          )}
          {selectedOption && selectedOption.textarea && (
            textArea(`${survey.id}.OptionQuestion${selectedOption.id}`, `surveyQuestionSpecifyText-${selectedOption.id}`, selectedOption.defaultLabel)
          )}
          {selectedOption && selectedOption.subQuestion && selectedOption.subQuestion.textarea && (
            textArea(`${survey.id}.OptionQuestion${selectedOption.subQuestion.id}`, `surveyQuestionSpecifyText-${selectedOption.id}`, selectedOption.defaultLabel)
          )}
        </div>
      ))}
    </>
  )
}

export default SurveyFormPart
