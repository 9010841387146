import React, { useContext, useEffect } from 'react'
import { Drawer, Layout } from 'antd'

import { ThemeContext } from '../../context/themeContext'
import {
  TAB_SIZE,
  DARK_THEME,
  LIGHT_THEME
} from '../../constants/ThemeSetting'

import SidebarContent from './SidebarContent'

const { Sider } = Layout

const Sidebar = () => {

  const themeContext = useContext(ThemeContext)
  const onToggleCollapsedNav = () => {
    themeContext.setNavCollapsed(!themeContext.navCollapsed)
    // setNavCollapsed(!navCollapsed)
  }

  const updateWindowWidth = () => {
    themeContext.setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', updateWindowWidth)
    return () => window.removeEventListener('resize', updateWindowWidth)
  })


  return (
    <Sider
      className={themeContext.width < TAB_SIZE ? 'collapsed-sidebar' : ''}
      width="280"
      trigger={null}
      collapsed={(themeContext.width < TAB_SIZE ? false : themeContext.miniMenu)}
      theme={themeContext.theme === DARK_THEME ? DARK_THEME : LIGHT_THEME}
      collapsible>
      {themeContext.width < TAB_SIZE ?
        <Drawer
          className={'drawer-sidebar vt-header-wrapper'}
          placement="left"
          closable={false}
          onClose={onToggleCollapsedNav}
          visible={themeContext.navCollapsed}>
          <SidebarContent/>
        </Drawer> :
        <SidebarContent/>
      }
    </Sider>
  )
}
export default Sidebar
