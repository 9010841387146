import { EventGroupEnum, EventTypeEnum } from '@vacationtracker/shared/types/core-event'

export const maxMonthsInPast = 3
export const maxMonthsRange  = 12 //complete plan can go anytime in past, but range should be 1 year because of data amount
export const maxPerPage  = 50

export const eventGroupsForCompanyLogs = [
  EventGroupEnum.BILLING,
  EventGroupEnum.BULK_ACTIONS,
  EventGroupEnum.COMPANY,
  EventGroupEnum.LEAVE_POLICY,
  EventGroupEnum.LEAVE_TYPE,
  EventGroupEnum.LOCATION,
  EventGroupEnum.LOCATION_YEAR_ROLLOVER,
  EventGroupEnum.NOTIFICATION,
  EventGroupEnum.TEAM,
  EventGroupEnum.AUTOMATION,
]

export const eventTypesForCompanyLogs = [
  EventTypeEnum.BILLING_CANCELLED,
  EventTypeEnum.BILLING_UPDATED,
  EventTypeEnum.COMPANY_CREATED,
  EventTypeEnum.COMPANY_SETTINGS_UPDATED,
  EventTypeEnum.IMPORT_USERS,
  EventTypeEnum.LEAVE_POLICY_DISABLED,
  EventTypeEnum.LEAVE_POLICY_ENABLED,
  EventTypeEnum.LEAVE_TYPE_CREATED,
  EventTypeEnum.LEAVE_TYPE_DELETED,
  EventTypeEnum.LEAVE_TYPE_UPDATED,
  EventTypeEnum.LOCATION_CREATED,
  EventTypeEnum.LOCATION_DELETED,
  EventTypeEnum.LOCATION_UPDATED,
  EventTypeEnum.LOCATION_WORK_WEEK_UPDATED,
  EventTypeEnum.LOCATION_YEAR_HOLIDAYS_CHANGED,
  EventTypeEnum.LOCATION_HOLIDAYS_AUTOIMPORT_CHANGED,
  EventTypeEnum.LOCATION_YEAR_ROLLOVER,
  EventTypeEnum.NOTIFICATION_CREATED,
  EventTypeEnum.NOTIFICATION_DELETED,
  EventTypeEnum.NOTIFICATION_UPDATED,
  EventTypeEnum.SUBSCRIPTION_CANCELED,
  EventTypeEnum.SUBSCRIPTION_CHANGED,
  EventTypeEnum.TEAM_APPROVER_STATUS_CHANGED,
  EventTypeEnum.TEAM_CREATED,
  EventTypeEnum.TEAM_DELETED,
  EventTypeEnum.TEAM_UPDATED,
  EventTypeEnum.BLACKOUT_PERIOD_CREATED,
  EventTypeEnum.BLACKOUT_PERIOD_UPDATED,
  EventTypeEnum.BLACKOUT_PERIOD_DELETED,
  EventTypeEnum.SENIORITY_ENTITLEMENT_CREATED,
  EventTypeEnum.SENIORITY_ENTITLEMENT_UPDATED,
  EventTypeEnum.SENIORITY_ENTITLEMENT_DELETED,
  EventTypeEnum.ENTITLEMENT_BY_ROLE_CREATED,
  EventTypeEnum.ENTITLEMENT_BY_ROLE_UPDATED,
  EventTypeEnum.ENTITLEMENT_BY_ROLE_DELETED,
]