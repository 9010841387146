import React, { useEffect, useState } from 'react'
import { Menu } from 'antd'
import { generateAddToCalendarLink } from '@vacationtracker/shared/functions/generate-link'
import Icon from '@ant-design/icons'

import googleCalendarIconSvg from '../../assets/images/google-calendar.svg'
import office365CalendarIconSvg from '../../assets/images/microsoft-office365-outlook.svg'

import { CalendarType } from '@vacationtracker/shared/types/features/add-to-calendar'
import { IGetLeaveRequestForSubscription } from '../../types/custom-queries'

const { Item } = Menu

interface IRequestData extends IGetLeaveRequestForSubscription {
  leaveTypeName: string
}
interface IAddToCalendarMenuProps {
  apiUrl: string
  requestData: IRequestData
  formatMessage: Function
}

export const AddToCalendarMenu = ({
  apiUrl,
  requestData,
  formatMessage,
}: IAddToCalendarMenuProps) => {
  const [googleCalendarLink, setGoogleCalendarLink] = useState('#')
  const [office365CalendarLink, setOffice365CalendarLink] = useState('#')

  useEffect(() => {
    const data = {
      companyId: requestData.user.companyId,
      userId: requestData.user.id,
      leaveTypeName: requestData.leaveTypeName,
      startDate: requestData.startDate,
      endDate: requestData.endDate,
      isPartDay: requestData.isPartDay,
      partDayStartHour: requestData.partDayStartHour,
      partDayEndHour: requestData.partDayEndHour,
    }
    setGoogleCalendarLink(generateAddToCalendarLink(CalendarType.Google, data, apiUrl, window.URL))
    setOffice365CalendarLink(generateAddToCalendarLink(CalendarType.Office365, data, apiUrl, window.URL))
  }, [requestData, apiUrl])

  const helpDeskArticle = 'https://vacationtracker.crisp.help/en/'
  return (<Menu>
    <Item key='cal-item-1'>
      <a target="_blank" rel="noopener noreferrer" href={googleCalendarLink}>
        <Icon component={googleCalendarIconSvg} width={12} height={12} /> { formatMessage({ id: 'app.addToGoogleCalendar' }) }
      </a>
    </Item>
    <Item key='cal-item-2'>
      <a target="_blank" rel="noopener noreferrer" href={office365CalendarLink}>
        <Icon component={office365CalendarIconSvg} width={12} height={12} /> { formatMessage({ id: 'app.addToMicrosoft365Calendar' }) }
      </a>
    </Item>
    <Item key='cal-item-3'>
      <a target="_blank" rel="noopener noreferrer" href={helpDeskArticle}>
        { formatMessage({ id: 'components.leaveRequestAction.addToCalendarMoreInfo' }) }
      </a>
    </Item>
  </Menu>)
}