import { RcFile } from 'antd/lib/upload'
import { API } from 'aws-amplify'
import axios from 'axios'


export const uploadAvatar = async (file: RcFile): Promise<string> => {
  const response = await API.get('CoreEvent', `/core/upload-avatar?type=${file.type}`, {
    headers: {
      'Content-Type': file.type,
    },
  })
  const uploadUrl: string = response.uploadUrl
  const imageUrl: string = response.imageUrl

  await axios.put(uploadUrl, file, {
    headers: {
      'Content-Type': file.type,
    },
  })

  return imageUrl
}
