import React, { useEffect, useState } from 'react'
import { LoadingOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Form, Row, Col, Input, Button, Modal, Typography } from 'antd'
import { useIntl } from 'react-intl'
import { isNumber } from 'lodash'
import { API } from 'aws-amplify'
import { format } from 'date-fns'


import { IUpcomingInvoiceInformation } from '@vacationtracker/shared/types/stripe'

import IntlMessages from '../../util/IntlMessages'
import { getDateFnsLocale } from '@vacationtracker/shared/i18n'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'

const { Paragraph, Text } = Typography

interface IUpdateSeatsProps {
  numberOfSeats: number | undefined
  numberOfActiveUsers: number | undefined
  onSubmit: (seats: number) => void
  isAnnualBilling: boolean
  locale: LocaleEnum
  inputLayout?: object
  style?: object
  showLabel?: boolean
}


export const UpdateSeats = (props: IUpdateSeatsProps): React.ReactElement => {
  const { numberOfSeats, onSubmit, showLabel, isAnnualBilling, locale, inputLayout, numberOfActiveUsers } = props
  const { formatMessage } = useIntl()
  const [seats, setSeats] = useState<number>(numberOfSeats || 25)
  const [isLoadingUpcomingInvoiceInfo, setIsLoadingUpcomingInvoiceInfo] = useState(false)
  const [upcomingInvoiceInfoMessage, setUpcomingInvoiceInfoMessage] = useState<React.ReactElement | string | null>(null)
  const [canNotDowngradeSeats, setCanNotDowngradeSeats] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  useEffect(() => {
    if (isNumber(numberOfSeats)) {
      setSeats(numberOfSeats)
    }
  }, [numberOfSeats])

  const computeAndSetUpcomingInvoiceInfoMessage = (upcomingInvoiceInfo: IUpcomingInvoiceInformation, seats: number, isDowngrade: boolean) => {
    if (upcomingInvoiceInfo) {
      if (upcomingInvoiceInfo.isTrial) {
        setUpcomingInvoiceInfoMessage(formatMessage({ id: isDowngrade
          ? 'billing.seats.warningOfSeatsRemovingProrateAdditionalNewBucketMonthly'
          : 'billing.seats.warningOfSeatsProrateAdditionalNewBucketMonthly' }, {
          noOfSeats: Math.abs(seats),
          amount: `$${upcomingInvoiceInfo?.nextInvoiceAmount}`,
          startDate: format(new Date(upcomingInvoiceInfo?.nextInvoiceDate as string), 'PPP', {locale: getDateFnsLocale(locale)}),
        }))

      } else if (upcomingInvoiceInfo.isSameInvoiceAmount) {
        setUpcomingInvoiceInfoMessage(formatMessage({ id: 'billing.seats.noBillingChange' }, {
          noOfSeats:  Math.abs(seats),
        }))
      } else if (isAnnualBilling) {
        setUpcomingInvoiceInfoMessage(<>
          <Paragraph>
            {formatMessage({ id: isDowngrade
              ? 'billing.seats.warningOfSeatsRemovingAnnualProrateAdditionalNewBucket'
              : 'billing.seats.warningOfSeatsAnnualProrateAdditionalNewBucket' }, {
              amount: `$${upcomingInvoiceInfo?.nextInvoiceAmount}`,
              noOfSeats: seats,
              startDate: format(new Date(upcomingInvoiceInfo?.nextInvoiceDate as string), 'PPP', {locale: getDateFnsLocale(locale)}),
            })}
          </Paragraph>
          {upcomingInvoiceInfo?.prorateAmount > 0 && <Paragraph>
            {formatMessage({ id: 'billing.seats.warningOfProrateAdditional' }, {
              proratedAmount: `$${upcomingInvoiceInfo.prorateAmount}`,
            })}
          </Paragraph>}
        </>)
      } else {
        {setUpcomingInvoiceInfoMessage(<>
          <Paragraph>
            {formatMessage({ id: isDowngrade
              ? 'billing.seats.warningOfSeatsRemovingProrateAdditionalNewBucketMonthly'
              : 'billing.seats.warningOfSeatsProrateAdditionalNewBucketMonthly' }, {
              noOfSeats:  Math.abs(seats),
              amount: `$${upcomingInvoiceInfo?.nextInvoiceAmount}`,
              startDate: format(new Date(upcomingInvoiceInfo?.nextInvoiceDate as string), 'PPP', {locale: getDateFnsLocale(locale)}),
            })}
          </Paragraph>
          {upcomingInvoiceInfo?.prorateAmount > 0 && <Paragraph>
            {formatMessage({ id: 'billing.seats.warningOfProrateAdditional' }, {
              proratedAmount: `$${upcomingInvoiceInfo.prorateAmount}`,
            })}
          </Paragraph>}
        </>
        )}
      }
    } else {
      return ''
    }
  }

  const callApiToGetUpcomingInvoiceInfo = async (seats: number) => {
    if (!isNumber(numberOfSeats) || !isNumber(numberOfSeats)) {
      return
    }
    setIsLoadingUpcomingInvoiceInfo(true)
    const currentNoOfSeats = numberOfSeats
    const differenceOfSeats = seats - currentNoOfSeats
    const info = await API.post('StripeApi', '/upcoming-invoice-information', {
      body: {
        quantity: seats,
        date: Math.ceil(new Date().getTime() / 1000),
      }})
    if (info) {
      computeAndSetUpcomingInvoiceInfoMessage(info, differenceOfSeats, Math.sign(differenceOfSeats) === -1)
      setIsLoadingUpcomingInvoiceInfo(false)
    }
  }

  const showBuyMoreSeatsConfirmModal = (seats: number) => {
    callApiToGetUpcomingInvoiceInfo(seats)
    setShowConfirmModal(true)
  }

  const handleSeatsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCanNotDowngradeSeats(false)
    if (numberOfActiveUsers && numberOfActiveUsers > Number(e.target.value)) {
      setCanNotDowngradeSeats(true)
      setSeats(Number(e.target.value))
      return
    }
    if (Number(e.target.value) < 25) {
      setSeats(25)
      return
    }
    setSeats(Number(e.target.value))
  }

  return  <div style={{ paddingTop: '10px'}}>
    <Form.Item
      label={showLabel && <IntlMessages id='billing.seats' />} wrapperCol={{ span: 24 }}
      extra={canNotDowngradeSeats ? <IntlMessages id='billing.seats.canNotDowngrade' values={{ currentNumberOfSeats: numberOfSeats }} /> : ''}
    >
      <Row gutter={8}>

        <Col {...inputLayout}>
          <Form.Item>
            <Input
              style={{ textAlign: 'center' }}
              type='number'
              value={seats}
              defaultValue={seats}
              onChange={handleSeatsChange}
            />
          </Form.Item>
        </Col>

        <Col xxl={18} xl={16} lg={14} md={12} sm={24}>
          <Form.Item>
            <Button
              icon={isLoadingUpcomingInvoiceInfo ? <LoadingOutlined /> : null}
              onClick={() => showBuyMoreSeatsConfirmModal(seats)}
              disabled={seats === numberOfSeats || isLoadingUpcomingInvoiceInfo || canNotDowngradeSeats}
            >
              <IntlMessages id='app.reviewAndConfirm' />
            </Button>
          </Form.Item>
        </Col>

      </Row>
    </Form.Item>

    {showConfirmModal && <Modal
      title={<IntlMessages id="billing.seats.manage" />}
      visible={showConfirmModal}
      closeIcon={<CloseCircleOutlined />}
      onCancel={() => {
        setUpcomingInvoiceInfoMessage(null)
        setCanNotDowngradeSeats(false)
        setSeats(numberOfSeats as number)
        setShowConfirmModal(false)
      }}
      okText={<IntlMessages id="components.modal.confirm" />}
      okButtonProps={{ danger: true }}
      onOk={() => {
        setShowConfirmModal(false)
        onSubmit(seats)
      }}
      cancelText={<IntlMessages id="app.cancel" />}
    >
      <>
        <Paragraph>
          {formatMessage({ id: (seats - Number(numberOfSeats)) > 0
            ? 'billing.seats.buyMoreSeatsConfirmModalContent'
            : 'billing.seats.buyLessSeatsConfirmModalContent',
          }, {
            currentNoOfSeats: numberOfSeats,
            additionalSeats: Math.abs(seats - Number(numberOfSeats)),
          })}
        </Paragraph>
        <div>
          {isLoadingUpcomingInvoiceInfo && <Text type='secondary'>
            <LoadingOutlined />&nbsp;
            {formatMessage({ id: 'billing.seats.loadingUpcomingPaymentInformation'})}
          </Text>}
          {!isLoadingUpcomingInvoiceInfo && upcomingInvoiceInfoMessage}
        </div>
        <div>
          {!isLoadingUpcomingInvoiceInfo && formatMessage({ id: 'app.areYouSure'})}
        </div>
      </>
    </Modal>}
  </div>
}