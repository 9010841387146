import React from 'react'
import { Button, Card, Drawer, Typography } from 'antd'

import IntlMessages from '../../util/IntlMessages'

import { Platform } from '@vacationtracker/shared/types/core-event'

interface Props {
  isHelpDrawerVisible: boolean
  onHelpDrawerClose: () => void
  startQuickTour: () => void
  userRole: string
  platform: Platform
}

const { Paragraph } = Typography

export const HelpDrawer: React.FC<Props> = ({
  isHelpDrawerVisible,
  onHelpDrawerClose,
  startQuickTour,
  userRole,
  platform,
}: Props) => {

  const onSupportChatOpen = () => {
    onHelpDrawerClose()
    window.$crisp.push(['do', 'chat:open'])
  }

  const getOnboardingLink = (platform: Platform, userRole: string): string => {
    if (platform === 'slack' && userRole === 'Admin') {
      return 'https://www.canva.com/design/DAE-nxQ-UtU/P6K_u-gnmG24PuYxw-ke1w/view'
    } else if (platform === 'slack' && userRole === 'Approver') {
      return 'https://www.canva.com/design/DAE-oGRaPh4/X99S5yQ5TRdMlx4ZDfp1bg/view'
    } else if (platform === 'slack' && userRole === 'User') {
      return 'https://www.canva.com/design/DAE-n6-vjnI/FLLB9jiA8F31rTy05YV6WQ/view'
    } else if (platform === 'microsoft' && userRole === 'Admin') {
      return 'https://www.canva.com/design/DAE-oCr10Rk/1DNK5oXcIpq3GRfQ4wScyw/view'
    } else if (platform === 'microsoft' && userRole === 'Approver') {
      return 'https://www.canva.com/design/DAE-oGyUj_M/GzSCoHxn8rJiiXSGx_Z2HQ/view'
    } else if (platform === 'microsoft' && userRole === 'User') {
      return 'https://www.canva.com/design/DAE-oAluyNY/ZbZ0CK3l40kgGztuvwjIhg/view'
    } else if ((platform === 'google' || platform === 'email') && userRole === 'Admin') {
      return 'https://www.canva.com/design/DAE-oEdrDPk/Vh39UCJwa0KD0-ykdlk2yg/view'
    } else if ((platform === 'google' || platform === 'email') && userRole === 'Approver') {
      return 'https://www.canva.com/design/DAE-oGFQkhE/0rzFzv65cnKooeoevyElkw/view'
    } else if ((platform === 'google' || platform === 'email') && userRole === 'User') {
      return 'https://www.canva.com/design/DAE-oLoIPBQ/TRTGxmXqmewNR0EVPgfzhg/view'
    } else {
      return 'error'
    }
  }

  return (
    <Drawer
      title={<IntlMessages id="components.helpDrawer.needhelp" />}
      placement="right"
      onClose={onHelpDrawerClose}
      visible={isHelpDrawerVisible}
      width={Math.min(450, window.innerWidth)}
    >
      <div className="help-drawer-inner">
        <Card
          title={<IntlMessages id="app.helpdesk" />}
          extra={
            <a href="https://vacationtracker.crisp.help/en/" target="_blank" rel="noreferrer">
              <IntlMessages id="app.visitHelpdesk" />
            </a>
          }
          className="help-drawer-card"
        >
          <Paragraph style={{ whiteSpace: 'break-spaces' }}>
            <IntlMessages id="components.helpDrawer.helpDeskInfo" />
          </Paragraph>
        </Card>
        <Card
          title={<IntlMessages id="components.helpDrawer.supportTitle" />}
          extra={(window && window.$crisp) ?
            <Button type="link" onClick={onSupportChatOpen}>
              <IntlMessages id="app.contactSupport" />
            </Button> :
            <a href="https://vacationtracker.crisp.help/en/" target="_blank" rel="noreferrer">
              <IntlMessages id="app.contactSupport" />
            </a>
          }
          className="help-drawer-card"
        >
          <Paragraph style={{ whiteSpace: 'break-spaces' }}>
            <IntlMessages
              id="components.helpDrawer.supportInfo"
              values={{
                link: (...chunks) => <a href="mailto:hello@vacationtracker.io">{chunks}</a>,
              }}
            />
          </Paragraph>
        </Card>
        <Card
          title={<IntlMessages id="components.helpDrawer.quickTourTitle" />}
          extra={<Button type="link" className="joyride-start" onClick={startQuickTour} style={{ paddingLeft: 0 }}>
            <IntlMessages id="app.takeQuickTour" />
          </Button>}
          className="help-drawer-card"
        >
          <Paragraph style={{ whiteSpace: 'break-spaces' }}>
            <IntlMessages id="components.helpDrawer.quickTourInfo" />
          </Paragraph>
        </Card>
        <Card
          title={
            userRole === 'User' ? <IntlMessages id="components.helpDrawer.userProductTourTitle" /> :
              userRole === 'Approver' ? <IntlMessages id="components.helpDrawer.approverProductTourTitle" /> :
                <IntlMessages id="components.helpDrawer.adminProductTourTitle" />
          }
          extra={
            <a href={getOnboardingLink(platform, userRole)} target="_blank" rel="noreferrer">
              <IntlMessages id="app.learnMore" />
            </a>
          }
          className="help-drawer-card"
        >
          {platform !== 'google' &&
            <Paragraph style={{ whiteSpace: 'break-spaces' }}>
              { userRole === 'User' && <IntlMessages id="components.helpDrawer.userProductTourInfo" /> }
              { userRole === 'Approver' && <IntlMessages id="components.helpDrawer.approverProductTourInfo" /> }
              { userRole === 'Admin' && <IntlMessages id="components.helpDrawer.adminProductTourInfo" /> }
              <a href="mailto:hello@vacationtracker.io">hello@vacationtracker.io</a>.
            </Paragraph>
          }
          {platform === 'google' &&
            <Paragraph style={{ whiteSpace: 'break-spaces' }}>
              { userRole === 'User' && <IntlMessages id="components.helpDrawer.google.userProductTourInfo" /> }
              { userRole === 'Approver' && <IntlMessages id="components.helpDrawer.google.approverProductTourInfo" /> }
              { userRole === 'Admin' && <IntlMessages id="components.helpDrawer.google.adminProductTourInfo" /> }
              <a href="mailto:hello@vacationtracker.io">hello@vacationtracker.io</a>.
            </Paragraph>
          }
        </Card>
      </div>
    </Drawer>
  )
}
