import React, { useContext, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Table, Tag } from 'antd'
import { eachDayOfInterval } from 'date-fns'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import isBetween from 'dayjs/plugin/isBetween'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import 'dayjs/locale/fr'
dayjs.extend(advancedFormat)
dayjs.extend(isToday)
dayjs.extend(isBetween)
dayjs.extend(advancedFormat)

import { ThemeContext } from '../../context/themeContext'
import { DARK_THEME } from '../../constants/ThemeSetting'

import IntlMessages from '@vacationtracker/shared/components/utils/IntlMessages'
import { UserAvatar } from '@vacationtracker/shared/components/user-avatar'
import { invertHexColor, hexToRGBA } from '@vacationtracker/shared/components/utils/invert-color-wrapper'

import { IColumn, IWallchartProps } from './types'
import { IGetLeaveRequestsByUsers, IWallchartUserLeavesData } from '../../types/wallchart'
import { IHolidayExtend } from '@vacationtracker/shared/types/holidays'

export const Wallchart = ({
  dateFrom,
  dateTo,
  leaves,
  calendarInfo,
  selectedLeaveType,
  toggleLeaveType,
  setFocusedLeaves,
  setFocusedUser,
  setFocusedHoliday,
  loadMore,
  locale,
}: IWallchartProps) : React.ReactElement=> {
  const { getLocationList, getLeaveTypeList } = calendarInfo
  const { theme } = useContext(ThemeContext)
  const nonWorkingDayColor = theme === DARK_THEME ? '#c3abdb' : '#EEEEEE'
  const holidayColor = '#7266BA'
  const tableRef = useRef<HTMLDivElement | null>(null)
  
  useEffect(() => {    
    const wrapper = tableRef.current?.getElementsByClassName('ant-table-body')[0]    
    let previousScrollLeft = wrapper?.scrollLeft || 0 
    let previousScrollTop = wrapper?.scrollTop || 0 
    const handleScroll = () => {
      const currentScrollLeft = wrapper?.scrollLeft as number
      const currentScrollTop = wrapper?.scrollTop as number
      if (
        (Math.abs(currentScrollLeft - previousScrollLeft) < Math.abs(currentScrollTop - previousScrollTop)) &&  //verticall scroll
        (currentScrollTop > previousScrollTop) &&   //bottom direction
        (wrapper?.scrollHeight as number - Number(wrapper?.scrollTop) - Number(wrapper?.clientHeight) < 1) //end of wrapper
      ){ 
        loadMore()
      }
      previousScrollLeft = currentScrollLeft
      previousScrollTop = currentScrollTop
    }

    wrapper?.addEventListener('scroll', handleScroll)

    return () => {
      wrapper?.removeEventListener('scroll', handleScroll)
    }
  }, [leaves])

  const dates = eachDayOfInterval({start: dateFrom, end: dateTo})


  function getHolidayHatch(isHalfDay?: boolean): string {
    const halfDay = isHalfDay || false
    const mainColor = hexToRGBA(holidayColor, halfDay ? 0.8 : 1)
    const colorWithOpacity = hexToRGBA(holidayColor, halfDay ? 0.4 : 0.66)
    return `repeating-linear-gradient(-45deg, ${mainColor}, ${mainColor} 4px, ${colorWithOpacity} 4px, ${colorWithOpacity} 8px)`
  }

  function createColumns(): IColumn[] {
    const columns:IColumn[] = [{
      title: '',
      dataIndex: 'rowheader',
      key: 'rowheader',
      width: 200,
      fixed: 'left',
      render: (text, data) => 
        <Link to={`/app/users/${data.userId}`}>
          <div className='profile-column'> 
            <UserAvatar id={data.userId} name={data.rowHeader} className="profile-button-avatar" shape="circle" avatarSize="default" avatar={data.url}></UserAvatar>
            <div className="profile-info">
              {text.split(' ')[0]}<br/>
              <div>{text.indexOf(' ') > 0 && text.substr(text.indexOf(' ') as number + 1)}</div>
            </div>
          </div>
        </Link>,
    }]
    
    for(let i = 0 ; i < dates.length ; i++){
      columns.push({
        title: dayjs(dates[i]).locale(locale).format('dd'),
        className: dayjs(dates[i]).isToday() ? 'today' : '',
        dataIndex: `col${i}`,
        key: `col${i}`,
        width: 40,
        render: (text, record, index: number) => (
          <div 
            onClick={() => onWallChartClick(index, dates[i])}
            className={`leave ${isPending(index, dates[i]) ? 'leave-pending' : ''} ${isMultiLeaveDay(index, dates[i]) ? 'leave-multi' : ''}`}
            style={styleLeaveType(index, dates[i])}>{text}
          </div>
        ),
      })
    }
    return columns
  }

  function isMultiLeaveDay(index: number, wallchartDate: Date) {
    const userLeave: IWallchartUserLeavesData | undefined = leaves[index].leaves
      .find((leave: IWallchartUserLeavesData) => dayjs(wallchartDate).isBetween(leave.startDate, leave.endDate, 'day', '[]'))
    return userLeave && !userLeave.hide && userLeave.isMultiLeaveDay
  }

  function isPending(index: number, wallchartDate: Date) {
    const userLeave: IWallchartUserLeavesData | undefined = leaves[index].leaves
      .find((leave: IWallchartUserLeavesData) => dayjs(wallchartDate).isBetween(leave.startDate, leave.endDate, 'day', '[]'))
    return !userLeave?.hide && userLeave?.status === 'OPEN'
  }

  function onWallChartClick(index: number, wallchartDate: Date) {
    const userLeave: IWallchartUserLeavesData | undefined = leaves[index].leaves
      .find((leave: IWallchartUserLeavesData) => !leave.hide && dayjs(wallchartDate).isBetween(leave.startDate, leave.endDate, 'day', '[]'))
    if (userLeave && !userLeave.hide) {
      const focusedLeaves = userLeave.isMultiLeaveDay ? leaves[index].leaves.filter(leave => dayjs(wallchartDate).isSame(leave.startDate)) : [userLeave]
      setFocusedLeaves(focusedLeaves)
      setFocusedUser(leaves[index].user)
    }
    const holiday = isHoliday(wallchartDate, leaves[index])
    if (holiday) {
      setFocusedHoliday(holiday)
    }
  }

  function createRows() {
    const rows:any[] = []
    for(let i=0 ; i < leaves.length ; i++){
      const row = {rowHeader: `Row${i}`, key: `row${i}`}
      row['rowheader'] = leaves[i].user.name
      row['url'] = leaves[i].user.imageUrl
      row['userId'] = leaves[i].user.id
      for(let j=0 ; j < dates.length ; j++){
        row[`col${j}`] = dates[j].getDate()
      }        
      rows.push(row)
    }    
    return rows
  }

  function styleLeaveType(index: number, wallchartDate: Date) {
    const isNonWorkingDayForUser = isNonWorkingDay(wallchartDate, leaves[index])

    const holiday = isHoliday(wallchartDate, leaves[index])
    
    if (holiday) {
      const holidayBackgroundColor = holiday.isHalfDay ? getHolidayHatch(isNonWorkingDayForUser) : (isNonWorkingDayForUser ? hexToRGBA(holidayColor, 0.5) : holidayColor)
      return { background: holidayBackgroundColor, color: invertHexColor(holidayColor, true) }
    }

    const userLeave: IWallchartUserLeavesData|undefined = leaves[index].leaves
      .find((leave: IWallchartUserLeavesData) => !leave.hide && dayjs(wallchartDate).isBetween(leave.startDate, leave.endDate, 'day', '[]'))
    
    if (!userLeave || userLeave.hide) {
      if (!userLeave && isNonWorkingDayForUser) {
        return { background: hexToRGBA(nonWorkingDayColor, 0.85), cursor: 'auto', color: invertHexColor(nonWorkingDayColor, true) }
      }
      return {cursor: 'auto'}
    }
    
    if (userLeave.status === 'OPEN' || userLeave.status === 'APPROVED') {
      const bgColor = userLeave?.isPartDay ? 'repeating-linear-gradient(45deg, #bbbabab0, #bbbabab0 1px, transparent 1px, transparent 5px)' : userLeave?.leaveType.color
      return { background: bgColor, backgroundColor: userLeave.leaveType.color, color: invertHexColor(userLeave.leaveType.color, true), opacity: isNonWorkingDayForUser ? 0.5 : 1 }
    }
    return {cursor: 'auto'}
  }

  function isNonWorkingDay(wallchartDate: Date, userLeaves: IGetLeaveRequestsByUsers): boolean {
    return !userLeaves.user.workWeek.includes(wallchartDate.getDay())
  }

  function isHoliday(wallchartDate: Date, userLeaves: IGetLeaveRequestsByUsers): IHolidayExtend|undefined {
    const userLocation = getLocationList.find(location => location.id === userLeaves.user.locationId)
    const wallchartHolidays = userLocation?.holidays.find(holidaysByYear => parseInt(holidaysByYear.year) === wallchartDate.getFullYear())
    const holiday = wallchartHolidays?.holidays.find(holiday => dayjs(holiday.date).isSame(wallchartDate))
    if (holiday && userLocation) {
      if (holiday.multiDayId) {
        const multiHoliday = wallchartHolidays?.holidays
          .filter(h => h.multiDayId === holiday.multiDayId)
          .sort((a: any, b: any) => dayjs(a.date).format() < dayjs(b.date).format() ? -1 : 1)
        if (multiHoliday) {
          return {
            ...holiday,
            date: multiHoliday[0].date,
            startDate: multiHoliday[0].date,
            endDate: multiHoliday[multiHoliday.length - 1].date,
            locationName: userLocation.name,
          }
        }
      }
      return {
        ...holiday,
        startDate: holiday.date,
        endDate: holiday.date,
        locationName: userLocation.name,
      }
    }
    return undefined
  }

  return (
    <div className='table-wrapper'>
      <Table
        ref={tableRef}
        columns={createColumns() as any}
        dataSource={createRows()}
        pagination={false}
        bordered={false}
        locale={{ emptyText: ' ' }}
        scroll={{x: 500, y: '50vw'}}
      />
      <div className='table-legend'>
        {getLeaveTypeList.map(leaveType => {
          return <Tag
            key={leaveType.id}
            style={{ opacity: !selectedLeaveType || selectedLeaveType === leaveType.id ? 1 : 0.5 }}
            color={leaveType.color}
            onClick={() => toggleLeaveType(leaveType.id)}
          >
            <span style={{color: invertHexColor(leaveType.color, true)}}>
              {leaveType.name}
            </span>
          </Tag>
        })}
        <span className='vt-span-separator' style={{ marginLeft: 5, marginRight: 13 }}>|</span>
        <Tag key="holidays" color='#fff' style={{ background: holidayColor, cursor: 'default' }}>
          <IntlMessages id="app.holidays" />
        </Tag>
        <Tag key="nonWorkingDay" style={{ background: hexToRGBA(nonWorkingDayColor, 0.85), cursor: 'default' }}>
          <IntlMessages id="app.nonWorkingDay" />
        </Tag>
        <Tag key="nonWorkingDayHalf" color='#fff' style={{ 
          cursor: 'default',
          background: '#222222 repeating-linear-gradient(45deg, #bbbabab0, #bbbabab0 1px, transparent 1px, transparent 5px)', 
        }}>
          <IntlMessages id="calendar.halfdayTagsShort" />
        </Tag>
      </div>
    </div>
  ) 
}
