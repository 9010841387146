import React from 'react'
import { Divider } from 'antd'
import IntlMessages from '../../util/IntlMessages'

interface IWorKWeek {
  days: number[]
  firstDayOfWeek: number
  separator?: JSX.Element | string
  withAnd?: string
}

const prefix = (index, workWeekLength, withAnd) => {
  return index === workWeekLength ? withAnd : ''
}

const suffix = (index, workWeekLength, separator, withAnd) => {
  if (withAnd.length > 0 && index === workWeekLength - 1) {
    return ' '
  }

  return index === workWeekLength ? '' : separator
}

const WorkWeek = ({ days, firstDayOfWeek, separator=<Divider type="vertical" />, withAnd=''}: IWorKWeek): React.ReactElement => {
  days.sort()

  if (days[0] === 0 && firstDayOfWeek === 1) {
    days.shift()
    days.push(0)
  }

  const dayNames = [
    'app.sunday',
    'app.monday',
    'app.tuesday',
    'app.wednesday',
    'app.thursday',
    'app.friday',
    'app.saturday',
  ]

  const workWeek = days.map(day => {
    return dayNames[day]
  })

  return (<>
    {workWeek.map((item, index) => {
      return (
        <span key={index}>
          {prefix(index + 1, workWeek.length, withAnd)}
          <IntlMessages id={item} />
          {suffix(index + 1, workWeek.length, separator, withAnd)}
        </span>
      )
    })}
  </>)
}

export default WorkWeek