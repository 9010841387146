import React, { useState } from 'react'
import { Modal, Button, Form } from 'antd'
import IntlMessages from '../../util/IntlMessages'
import { API } from 'aws-amplify'
import { SelectChannelFormFragment } from '../select-channel-form-fragment'
import { track } from '../../services/analytics/analytics'

interface Props {
  visible: boolean
  onCancel: () => void
  platform: string
}

export const AnnouncementModal: React.FC<Props> = ({ visible, onCancel, platform}: Props) => {
  const [form] = Form.useForm()
  const [isSending, setSending] = useState<boolean>(false)

  const sendAnnounceMessage = async () => {
    try {
      const values = await form.validateFields()
      setSending(true)
      await API.post('CoreEvent', '/core/event', {
        body: {
          eventType: 'ANNOUNCE_VT_COMPANY',
          eventGroup: 'BULK_ACTIONS',
          destination: platform === 'google' || platform === 'email'
            ? values.channel.map(element => element.name).join()
            : values.channel,
        },
      })
      track('ANNOUNCEMENT_SENT', { platform })
      setSending(false)
      onCancel()
    } catch(err) {
      console.error(err)
      setSending(false)
    }
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
  }

  return (
    <Modal
      title={<IntlMessages id="components.announcementModal.announceToCompany" />}
      visible={visible}
      footer={null}
      onCancel={onCancel}
    >

      <Form
        {...formItemLayout}
        key="channelForm"
        form={form}
        name="channelForm"
        onFinish={sendAnnounceMessage}
      >
        <SelectChannelFormFragment />

        <Button type="primary" htmlType="submit" disabled={isSending} loading={isSending} >
          <IntlMessages id="components.announcementModal.send" />
        </Button>
      </Form>

    </Modal>
  )
}
