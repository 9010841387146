import React from 'react'
import { LeaveDisplayFormatEnum } from '../../types/display-in-days-hours'
import { IQuotaBoxProps } from './types'
import { BroughtForwardTooltip, ExpirationTooltip, QuotaTooltip, RemainingDaysTooltip, UsedDaysTooltip } from '../user-leave-quotas/quota-tooltips'
import { DisplayLeaveInDaysAndHours } from '../display-leave-in-days-and-hours'

type QuotaContext = 'broughtForward' | 'quota' | 'used' | 'remaining' | 'expired'

const QuotaBox = ({
  title,
  dataTestId,
  value,
  quota,
  footer,
  hourlyLeaveAccounting,
  doNotShowDisplayInDaysOrHoursTooltip = false,
  hasRequestedDays = false,
  locationName,
  leaveTypeName,
  broughtForwardLimit,
  expirationDate,
  locale,
  scheduledDays,
}: IQuotaBoxProps): React.ReactElement => {
  const context = (title as JSX.Element).props?.id?.split('.')?.pop() as QuotaContext
  let tooltip
  switch (context) {
    case 'broughtForward':
      tooltip = <BroughtForwardTooltip
        value={value} locationName={locationName} leaveTypeName={leaveTypeName} broughtForwardLimit={broughtForwardLimit} hourlyLeaveAccounting={hourlyLeaveAccounting}
      />
      break
    case 'quota':
      tooltip = <QuotaTooltip value={value} quota={quota} hourlyLeaveAccounting={hourlyLeaveAccounting}/>
      break
    case 'used':
      tooltip = <UsedDaysTooltip value={value} scheduledDays={scheduledDays} hourlyLeaveAccounting={hourlyLeaveAccounting} leaveTypeName={leaveTypeName}/>
      break
    case 'remaining':
      tooltip = <RemainingDaysTooltip value={value} hourlyLeaveAccounting={hourlyLeaveAccounting} leaveTypeName={leaveTypeName} broughtForwardLimit={broughtForwardLimit}/>
      break
    case 'expired':
      tooltip = <ExpirationTooltip expirationDate={expirationDate} locale={locale}/>
      break
    default:
      tooltip = <></>
      break
  }

  return (
    <div className={`quota quota-box ${hasRequestedDays ? 'requested' : ''} ${context}`}>
      <div className="quota-box-title" data-testid={`${dataTestId}-title`}>
        {doNotShowDisplayInDaysOrHoursTooltip ? title : <>{title}{tooltip}</>}
      </div>
      <div className="quota-box-value" data-testid={`${dataTestId}-value`}>
        {formatValue(value)}
      </div>
      <div className="quota-box-footer" data-testid={`${dataTestId}-footer`}>
        {footer}
      </div>
    </div>
  )
}

function formatValue(value: string | number): string | React.ReactElement {
  if (typeof value === 'number') {
    return <DisplayLeaveInDaysAndHours
      value={value}
      format={LeaveDisplayFormatEnum.short}
    />
  } else {
    return value
  }
}

export default QuotaBox
