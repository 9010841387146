import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Button, Card, Col, Drawer, Row, Space, Typography } from 'antd'

import IntlMessages from '@vacationtracker/shared/components/utils/IntlMessages'
import { getLeavePeriod } from '@vacationtracker/shared/functions/get-leave-period'
import { UserAvatar } from '@vacationtracker/shared/components/user-avatar'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'

import { IWallchartUserData, IWallchartUserLeavesData } from '../../types/wallchart'
import { IGetLocationListForCalendar } from '../../types/custom-queries'
import { IGetTeamsShort } from '../../types/teams'
import DenyWithReasonForm from '../deny-with-reason-form'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'

const { Text } = Typography

interface IWallchartLeaveDrawer {
  showDrawer: boolean
  focusedLeaves?: IWallchartUserLeavesData[]
  focusedUser?: IWallchartUserData
  locationList?: IGetLocationListForCalendar[]
  teamList?: IGetTeamsShort[]
  locale: LocaleEnum
  loading: boolean
  onClose: () => void
  approveLeaveRequest: (leaveIndex: number) => void
  denyLeaveRequest: (leaveIndex: number, reason?: string) => Promise<void>
  hourFormat: HourFormatEnum
}

export const WallchartLeaveDrawer = ({
  showDrawer,
  focusedLeaves,
  focusedUser,
  locationList,
  teamList,
  locale,
  loading,
  onClose,
  approveLeaveRequest,
  denyLeaveRequest,
  hourFormat,
}: IWallchartLeaveDrawer) : React.ReactElement=> {
  const { formatMessage } = useIntl()
  const [showDenyReasonModal, setShowDenyReasonModal] =  useState<boolean>(false)
  const [actionLeaveRequestIndex, setActionLeaveRequestIndex] =  useState<number>(0)

  return (
    <Drawer
      title={<IntlMessages id="components.userTodayOff.leaveInfo" />}
      placement="right"
      onClose={() => onClose()}
      visible={showDrawer}
      width={Math.min(400, window.innerWidth)}
      className="wallchart-leave-drawer"
    >
      {focusedLeaves?.map((focusedLeave, index) => (
        <div key={focusedLeave.id}>
          {showDrawer && focusedUser && locationList && teamList && !focusedLeave.hide &&
            <Card>
              <Row justify="start" gutter={[0,15]}>
                <Col span={24}>
                  <Space direction="vertical" size={[1, -15]} wrap>
                    <Text strong><IntlMessages id="leaveRequests.leaveTypeName"/>:</Text>
                    <Text data-testid="leave-type-name">{focusedLeave.leaveType.name}</Text>
                  </Space>
                </Col>
                <Col span={24}>
                  <Link to={`/app/users/${focusedUser.id}`}>
                    <div className='profile-column'>
                      <UserAvatar id={focusedUser.id} name={focusedUser.name} className="profile-button-avatar" shape="circle" avatarSize="default" avatar={focusedUser.imageUrl} />
                      <div className="profile-info">
                        {focusedUser.name.split(' ')[0]}<br/>
                        <div>{focusedUser.name.indexOf(' ') > 0 && focusedUser.name.substr(focusedUser.name.indexOf(' ') as number + 1)}</div>
                      </div>
                    </div>
                  </Link>
                </Col>
                <Col span={24}>
                  <Space direction="vertical" size={[1, -15]} wrap>
                    <Text strong><IntlMessages id="app.location"/>:</Text>
                    <Text data-testid="user-location">
                      <Link to={`/app/settings/locations/${focusedLeave?.locationId}`}>
                        {locationList.find(location => location.id === focusedLeave?.locationId)?.name}
                      </Link>
                    </Text>
                  </Space>
                </Col>
                <Col span={24}>
                  <Space direction="vertical" size={[1, -15]} wrap>
                    <Text strong><IntlMessages id="app.department"/>:</Text>
                    <Text data-testid="user-department">
                      <Link to={`/app/settings/departments/${focusedLeave?.teamId}`}>
                        {teamList.find(team => team.id === focusedLeave?.teamId)?.name}
                      </Link>
                    </Text>
                  </Space>
                </Col>
                <Col span={24}>
                  <Space direction="vertical" size={[1, -15]} wrap>
                    <Text strong><IntlMessages id="components.leaveRequestAction.periodLabel"/></Text>
                    <Text data-testid="user-date-range">
                      {
                        getLeavePeriod({
                          isPartDay: focusedLeave?.isPartDay,
                          startDate: focusedLeave?.startDate,
                          endDate: focusedLeave?.endDate,
                          partDayStartHour: focusedLeave?.partDayStartHour,
                          partDayEndHour: focusedLeave?.partDayEndHour,
                          formatMessage,
                          leaveRequestWorkingDays: focusedLeave?.workingDays,
                          locale,
                          hourFormat,
                        })
                      }
                    </Text>
                  </Space>
                </Col>
                {focusedLeave?.reason &&
                  <Col span={24}>
                    <Space direction="vertical" size={[1, -15]} wrap>
                      <Text strong><IntlMessages id="leaveRequests.reason"/>:</Text>
                      <Text data-testid="user-department">{focusedLeave.reason}</Text>
                    </Space>
                  </Col>
                }
                {focusedLeave?.status === 'OPEN' &&
                  <Col span={24}>
                    <Space size={[8, 16]} wrap>
                      <Button loading={loading} onClick={() => {denyLeaveRequest(index)}} type="primary" danger>
                        <IntlMessages id="app.deny" />
                      </Button>
                      <Button loading={loading} onClick={() => {setShowDenyReasonModal(true), setActionLeaveRequestIndex(index)}} type="primary" danger>
                        <IntlMessages id="app.denyWithReason" />
                      </Button>
                      <Button loading={loading} onClick={() => approveLeaveRequest(index)} type="primary">
                        <IntlMessages id="app.approve" />
                      </Button>
                    </Space>
                  </Col>
                }
                {focusedLeave?.status === 'APPROVED' &&
                  <Col span={24}>
                    <Text strong><IntlMessages id="components.leaveRequestAlertStatus.leaveRequestHasApprovedByYouInfo"/></Text>
                  </Col>
                }
              </Row>
            </Card>
          }

        </div>
      ))}
      {showDenyReasonModal && focusedLeaves && focusedLeaves[actionLeaveRequestIndex] &&
        <DenyWithReasonForm
          visibleModal={true}
          leaveRequest={focusedLeaves[actionLeaveRequestIndex]}
          handleCancel={() => (setShowDenyReasonModal(false))}
          onSave={async (data) => {
            await denyLeaveRequest(actionLeaveRequestIndex, data.statusReason as string)
            setShowDenyReasonModal(false)
          }}
        />
      }

    </Drawer>
  )
}
