import React, { useState } from 'react'
import { Modal, Form, Select } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import IntlMessages from '../../util/IntlMessages'
import { IUserFilteredInfo } from '../../types/users'

interface IChangeLocationForm {
  onSave: Function
  handleCancel: Function
  locations: {
    id: string
    name: string
  }[]
  visibleModal: boolean
  selectedUser?: IUserFilteredInfo
}

const ChangeLocationForm = ({ onSave, handleCancel, visibleModal, locations, selectedUser }: IChangeLocationForm): React.ReactElement => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)

  const onFinish = () => {
    form
      .validateFields()
      .then(values => {
        setIsLoading(true)
        onSave(values.location)
      })
      .catch(info => {
        console.log('Validate Failed:', info)
      })
  }

  const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 15 },
  }

  return (
    <Modal
      title={<IntlMessages id="components.locationForm.changeUserLocation" />}
      visible={visibleModal}
      closeIcon={<CloseCircleOutlined />}
      onCancel={() => handleCancel()}
      confirmLoading={isLoading}
      okText={<IntlMessages id="components.modal.confirm" />}
      onOk={onFinish}
      cancelText={<IntlMessages id="app.cancel" />}
    >
      <Form
        key="ChangeLocationForm"
        form={form}
        name="ChangeLocationForm"
        onFinish={onFinish}
      >
        <p><IntlMessages id="components.locationForm.changeLocationWarning" /></p>
        {selectedUser &&
          <Form.Item
            {...formItemLayout}
            name="location"
            label={<IntlMessages id="locations.selectLocation" />}
            rules={[{ required: true, message: <IntlMessages id="components.locationForm.selectLocationError" /> }]}
          >
            <Select>
              {locations.filter(location => location.id !== selectedUser.location.id).map(location =>
                <Select.Option key={location.id} value={location.id}>
                  {location.name}
                </Select.Option>
              )}
            </Select>
          </Form.Item>
        }
      </Form>
    </Modal>
  )
}

export default ChangeLocationForm
