import React, { Fragment } from 'react'
import { Alert } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import 'moment/locale/fr'

import IntlMessages from '../../util/IntlMessages'
import { IUserToday } from '../../types/custom-queries'
import { useAppSelector } from '../../store/hooks'
import { selectLocaleSlice } from '../../store/locale-slice'
import { getSafeLocale } from '@vacationtracker/shared/i18n'

interface IUserTodayOff {
  todayLeaves: IUserToday[]
}

const UserTodayOff = ({ todayLeaves }: IUserTodayOff): React.ReactElement => {
  const { locale } = useAppSelector(selectLocaleSlice)
  const safeLocale = getSafeLocale(locale.locale)

  return (
    <Alert type="success"
      icon={<InfoCircleOutlined />}
      showIcon
      style={{ marginBottom: 20 }}
      message={<IntlMessages id="components.userTodayOff.leaveInfo" />}
      description={todayLeaves.map( todayOff => {
        return (<Fragment key={todayOff.id}>
          <div className="user-offtoday">
            {moment(todayOff.startDate).format('YYYY-MM-DD') === moment(todayOff.endDate).format('YYYY-MM-DD') ?
              <>
                {
                  todayOff.isPartDay ?
                    <IntlMessages id="components.userTodayOff.offTodayPartDay" values={{
                      leaveTypeName: todayOff.leaveType.name,
                      from: todayOff.partDayStartHour,
                      to: todayOff.partDayEndHour,
                    }} /> :
                    <IntlMessages id="components.userTodayOff.offTodayFullDay" values={{
                      leaveTypeName: todayOff.leaveType.name,
                      today: moment(todayOff.startDate).locale(safeLocale).format('MMMM D, yyyy'),
                    }} />
                }
              </> :
              <IntlMessages id="components.userTodayOff.offFromTo" values={{
                leaveTypeName: todayOff.leaveType.name,
                startDate: moment(todayOff.startDate).locale(safeLocale).format('MMMM D, yyyy'),
                endDate: moment(todayOff.endDate).locale(safeLocale).format('MMMM D, yyyy'),
              }} />
            }
          </div>
          {todayOff.reason &&
            <p className="user-offtoday-reason">
              <IntlMessages id="components.userTodayOff.offTodayReason" values={{ reason: todayOff.reason }} />
            </p>
          }
        </Fragment>)
      })}
    />
  )
}

export default UserTodayOff
