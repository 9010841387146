import React, { useEffect, useRef } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { Observable, ZenObservable } from 'zen-observable-ts'

import { whatsNewResponseSubscription } from '../../graphql/custom-queries'
import { handleUnsubscribe } from '../../util/notifications'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { selectUserIdSlice } from '../../store/user-id-slice'
import { addWhatsNewId } from '../../store/whats-news-slice'

import { IWhatsNewExtendEvent } from '@vacationtracker/shared/types/whats-new'

let delay = 15

const WhatsNewNotifications = () => {
  const { userId } = useAppSelector(selectUserIdSlice)
  const dispatch = useAppDispatch()
  const sub = useRef<ZenObservable.Subscription[]>([])

  const handleSubscription = () => {
    const subscription = API.graphql(
      graphqlOperation(whatsNewResponseSubscription, { userId })
    ) as Observable<object>

    const subs = subscription.subscribe({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      next: (data: any) => {
        delay = 10
        let subscriptionEvent: IWhatsNewExtendEvent | null = null
        try {
          subscriptionEvent = JSON.parse(data.value.data.whatsNewResponse.originalEvent)
        } catch(err) {
          // Ignoring the error
          return
        }

        if (subscriptionEvent) {
          dispatch(addWhatsNewId(subscriptionEvent.announcementId as string))
        }
      },
      error: () => {
        if ((delay * 2) < 3.6e+6) {
          delay *= 2
        }
        setTimeout(() => {
          handleSubscription()
        }, delay)
      },
    })

    return subs
  }

  useEffect(() => {
    if (!userId) {
      handleUnsubscribe(sub.current)
      return
    }
    sub.current.push(handleSubscription())
    return () => {
      handleUnsubscribe(sub.current)
    }
  }, [userId])

  
  return (<></>)
}

export default WhatsNewNotifications
