/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Modal, Form, Table, Button, Typography, Input, Popconfirm } from 'antd'
import { CloseCircleOutlined, SearchOutlined, LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons'

import IntlMessages from '../../util/IntlMessages'
import useDebounce from '../../util/use-debounce'
import { IEditableCell, ILabel, IUserManageLabels } from './types'

const { Link } = Typography

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  children,
  ...restProps
}: IEditableCell) => {

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: <IntlMessages id="components.userManageLabels.isRequired" values={{ title }} />,
            },
          ]}
        >
          <Input type={inputType} />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

const UserManageLabels = ({ labels, visibleModal, closeModal, updateLabel, deleteLabel }: IUserManageLabels): React.ReactElement => {
  const { formatMessage } = useIntl()

  const [labelsData, setLabelsData] = useState<ILabel[]>([])
  const [form] = Form.useForm()
  const [editingId, setEditingId] = useState('')
  const [deleteLabelId, setDeleteLabelId] = useState('')
  const [searchLabelText, setSearchLabelText] = useState<string>('')

  const debouncedSearchTerm = useDebounce(searchLabelText.toLowerCase(), 500)

  useEffect(() =>  {
    if (debouncedSearchTerm) {
      setLabelsData(labels.filter(label => label.name.toLowerCase().includes(debouncedSearchTerm)))
    } else {
      setLabelsData(labels)
    }
  }, [debouncedSearchTerm])

  useEffect(() => {
    setLabelsData(labels.sort((a, b) => a.name < b.name ? -1 : 1))
    setDeleteLabelId('')
  }, [labels])

  const isEditing = (record: ILabel) => record.id === editingId

  const onEdit = (record: ILabel) => {
    form.setFieldsValue(record)
    setEditingId(record.id)
  }

  const onDelete = (record: ILabel) => {
    setDeleteLabelId(record.id)
    deleteLabel && deleteLabel(record.id)
  }

  const onSave = async (id: string) => {
    try {
      const row = await form.validateFields()
      updateLabel && updateLabel(row, id)
      setEditingId('')
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
    }
  }

  const columns = [
    {
      title: `${formatMessage({ id: 'app.name' })}`,
      dataIndex: 'name',
      key: 'name',
      editable: true,
    },
    {
      title: `${formatMessage({ id: 'app.color' })}`,
      dataIndex: 'color',
      key: 'color',
      editable: true,
      render: (color: string) => {
        return <div className="label-color" style={{ backgroundColor: color }} ></div>
      },
      width: 150,
    },
    {
      title: `${formatMessage({ id: 'components.userManageLabels.userCount' })}`,
      dataIndex: 'userCount',
      key: 'userCount',
      width: 100,
    },
    {
      title: `${formatMessage({ id: 'app.actions' })}`,
      dataIndex: 'id',
      width: 140,
      render: (id: string, record: ILabel) => {
        const editable = isEditing(record)
        return editable ? (
          <span>
            <Button size="small" onClick={() => onSave(record.id)} style={{ marginRight: 8 }}>
              <IntlMessages id="app.save" />
            </Button>
            <Button size="small" onClick={() => setEditingId('')}>
              <IntlMessages id="app.cancel" />
            </Button>
          </span>
        ) : (
          <>
            <Link disabled={editingId !== ''} onClick={() => onEdit(record)}>
              <IntlMessages id="app.edit" />
            </Link>
            <Popconfirm
              title={<IntlMessages id="components.userManageLabels.deleteLabel" />}
              onConfirm={() => onDelete(record)}
              okText={<IntlMessages id="app.delete" />}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              overlayClassName="delete-label"
            >
              <Link type="danger" style={{ marginLeft: 10 }} disabled={editingId !== ''}>
                {id === deleteLabelId ? <LoadingOutlined /> : <IntlMessages id="app.delete" />}
              </Link>
            </Popconfirm>
          </>
        )
      },
    },
  ]

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: ILabel) => ({
        record,
        inputType: col.dataIndex === 'color' ? 'color' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })


  return (
    <Modal
      title={<IntlMessages id="app.manageLabels" />}
      visible={visibleModal}
      closeIcon={<CloseCircleOutlined />}
      className="manage-labels"
      style={{ top: 20 }}
      bodyStyle={{ padding: 12 }}
      onCancel={() => closeModal()}
      width={800}
      footer={[
        <Button key="close" onClick={() => closeModal()}>
          <IntlMessages id="app.done" />
        </Button>,
      ]}
    >
      <Input
        placeholder={`${formatMessage({ id: 'components.userManageLabels.findLabel' })}`}
        suffix={searchLabelText ? null : <SearchOutlined />}
        allowClear
        style={{ width: 250, marginBottom: 10 }}
        size='middle'
        value={searchLabelText}
        onChange={e => {
          setSearchLabelText(e.target.value)
        }}
      />
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          rowKey={record => record.id}
          size="small"
          pagination={false}
          dataSource={labelsData}
          columns={mergedColumns}
          rowClassName={record => record.id === deleteLabelId ? 'disabled-row editable-row' : 'editable-row'}
        />
      </Form>
    </Modal>
  )
}

export default UserManageLabels