import React, { useState, useEffect } from 'react'
import { isAfter, differenceInMinutes } from 'date-fns'
import { Button, Modal } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'

import { useAppSelector } from '../../store/hooks'
import { selectAuthCompanySlice } from '../../store/auth-company-slice'
import { selectAuthUserSlice } from '../../store/auth-user-slice'
import { useHistory } from 'react-router'


const ZOHO_POP_SURVEY_SRC = 'https://survey.zohopublic.com'
export const SURVEY_TRIAL_EXTEND = 'surveyTrialExtend'
export enum ZohoSurveysEnum {
  trialSurvey = 'SdD7tr',
  trialExtendSurvey = 'HkzNON'
}

interface IZohoPopUpSurvey {
  surveyId: string
  surveyLocalStorageKey: string
  showZohoSurvey?: boolean
  setShowZohoSurvey?: (v) => void
  timeOffsetMinutes?: number
  remindMeLaterTimeOffset?: number
}

interface IGetTimeDiffArgs {
  dateToCompare: Date
  surveyLocalStorageKey: string
  userId: string
}

interface IShouldShowSurveyArgs {
  isSurveyDone: boolean
  timeDiff: number
  surveyLocalStorageKey: string
  isRemindMeLaterClicked: boolean
  isTrialExtended: boolean
  userId: string
  showZohoSurvey?: boolean
  timeOffsetMinutes?: number
  remindMeLaterTimeOffset?: number
}

interface ISetDashboardSurveyInStorage {
  id: string
  isDone: boolean
  time?: Date
  remindMeLaterTime?: Date
  isRemindMeLaterClicked?: boolean
}

const setDashboardSurveyInStorage = (data: ISetDashboardSurveyInStorage, surveyLocalStorageKey: string): void => {
  localStorage.setItem(surveyLocalStorageKey, JSON.stringify(data))
}

const getTimeDiff = ({
  dateToCompare,
  surveyLocalStorageKey,
  userId,
}: IGetTimeDiffArgs): number => {
  const dashboardSurveyStorage = JSON.parse(localStorage.getItem(surveyLocalStorageKey) as string)
  // check if dashboardSurvey is in storage and pick time
  const surveyTime = dashboardSurveyStorage && new Date(dashboardSurveyStorage?.time as string)
  const now = new Date()
  const companySignUpDate = new Date(dateToCompare)

  // this check is to write time of company creation in storage on the first time dashboard is loaded
  // after finished signup
  // if no surveyTime -> then use company timestamp as starting reference
  // if there is surveyTime (user clicked close on survey or it is not the first time on dashboard page)
  // than compare it with companySignUpDate
  // if it is after companySignUpDate then leave it
  if (!surveyTime || !isAfter(surveyTime as Date, companySignUpDate)) {
    setDashboardSurveyInStorage({
      isDone: false,
      id: userId,
      time: companySignUpDate,
      remindMeLaterTime: new Date(),
      isRemindMeLaterClicked: false,
    },
    surveyLocalStorageKey
    )
    return Math.abs(Number(differenceInMinutes(companySignUpDate, now)))
  }

  return Math.abs(Number(differenceInMinutes(new Date(JSON.parse(localStorage.getItem(surveyLocalStorageKey) as string)?.time as Date), now)))
}

const shouldShowSurvey = ({
  isSurveyDone,
  timeOffsetMinutes,
  timeDiff,
  remindMeLaterTimeOffset,
  surveyLocalStorageKey,
  isRemindMeLaterClicked,
  showZohoSurvey,
  isTrialExtended,
  userId,
}: IShouldShowSurveyArgs): boolean => {
  if (showZohoSurvey) {
    return showZohoSurvey
  }
  if (isTrialExtended) {
    setDashboardSurveyInStorage({
      isDone: true,
      id: userId,
      time: new Date(),
      isRemindMeLaterClicked: false,
      remindMeLaterTime: new Date(),
    },
    surveyLocalStorageKey
    )
    return false
  }
  if (remindMeLaterTimeOffset && isRemindMeLaterClicked) {
    const now = new Date()
    const dateToCompare = new Date(JSON.parse(localStorage.getItem(surveyLocalStorageKey) as string)?.remindMeLaterTime as Date)
    const remindMeLaterTimeDiff = Math.abs(Number(differenceInMinutes(dateToCompare, now)))
    return !isSurveyDone && remindMeLaterTimeDiff >= remindMeLaterTimeOffset
  }

  if (timeOffsetMinutes && timeOffsetMinutes > 0) {
    return !isSurveyDone && timeDiff >= timeOffsetMinutes
  } else {
    return !isSurveyDone
  }
}


const ZohoPopUpSurvey = ({
  surveyId,
  surveyLocalStorageKey,
  timeOffsetMinutes,
  remindMeLaterTimeOffset,
  showZohoSurvey,
  setShowZohoSurvey,
}: IZohoPopUpSurvey) => {
  const zohoPopSurveyUrl = `${ZOHO_POP_SURVEY_SRC}/zs/${surveyId}`
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const [surveySrc, setSurveySrc] = useState('')
  const [isSurveyDone, setIsSurveyDone] = useState(JSON.parse(localStorage.getItem(surveyLocalStorageKey) as string)?.isDone)
  const [showSurveyModal, setShowSurveyModal] = useState(false)
  const [isRemindMeLaterClicked, setIsRemindMeLaterClicked] = useState(JSON.parse(localStorage.getItem(surveyLocalStorageKey) as string)?.isRemindMeLaterClicked)

  const handleUnsubscribe = (event) => {
    if (event.origin === ZOHO_POP_SURVEY_SRC && surveySrc === '') {
      setSurveySrc(`${zohoPopSurveyUrl}?email=${authUser.email}&userId=${authUser.id}&companyId=${authCompany?.id}`)
    }
    if (event.origin === ZOHO_POP_SURVEY_SRC && showZohoSurvey) {
      const zohoPopSurvey = document.querySelectorAll(`[src="${zohoPopSurveyUrl}"]`)
      const zohoIFrameElement: Element & {src?: string} = zohoPopSurvey && zohoPopSurvey[0]
      if (surveySrc !== '' && zohoIFrameElement) {
        zohoIFrameElement.src = `${zohoPopSurveyUrl}?email=${authUser.email}&userId=${authUser.id}&companyId=${authCompany?.id}`
      }
    }
    if (event.origin === ZOHO_POP_SURVEY_SRC && event.data?.zohosurvey_respondentStatus === 'completed') {
      setDashboardSurveyInStorage({
        isDone: true,
        id: authUser.id,
        time: new Date(),
        isRemindMeLaterClicked: false,
        remindMeLaterTime: new Date(),
      },
      surveyLocalStorageKey
      )
      setIsRemindMeLaterClicked(false)
      setIsSurveyDone(true)
      setShowZohoSurvey && setShowZohoSurvey(false)
      setTimeout(() => {
        // give a user couple of seconds to read Thank you message
        // after finishing the survey
        // then close the survey modal
        setShowSurveyModal(false)
        history.push('/app/dashboard')
      }, 4000)
    }
  }

  const handleCancel = () => {
    if (!isSurveyDone) {
      setDashboardSurveyInStorage({
        id: authUser?.id,
        isDone: false,
        time: new Date(),
        isRemindMeLaterClicked: false,
        remindMeLaterTime: new Date(),
      },
      surveyLocalStorageKey
      )
    }
    setIsRemindMeLaterClicked(false)
    setShowSurveyModal(false)
    setShowZohoSurvey && setShowZohoSurvey(false)
    setIsSurveyDone(false)
  }

  const handleRemindMeLater = () => {
    setDashboardSurveyInStorage({
      id: authUser?.id,
      isDone: false,
      time: new Date(),
      isRemindMeLaterClicked: true,
      remindMeLaterTime: new Date(),
    }, surveyLocalStorageKey)
    setShowSurveyModal(false)
    setShowZohoSurvey && setShowZohoSurvey(false)
    setIsSurveyDone(false)
  }

  useEffect(() => {
    if (authCompany?.subscriptionStatus === 'trialing' && authUser.role === 'Admin') {
      const timeDiff = getTimeDiff({
        dateToCompare: new Date(authCompany.timestamp as string),
        surveyLocalStorageKey,
        userId: authUser.id,
      })
      // use the time timeDiff to not show pop up survey
      // unless timeOffsetMinutes prop value passes after first landing on dashborad
      // or after clicking on close on popup survey modal w/out finishing survey
      // if remindMeLaterTimeOffset is passed down as a prop
      // then that will be used as priority
      if (shouldShowSurvey({
        isSurveyDone,
        timeOffsetMinutes,
        timeDiff,
        remindMeLaterTimeOffset,
        surveyLocalStorageKey,
        isRemindMeLaterClicked,
        showZohoSurvey,
        isTrialExtended: surveyId === ZohoSurveysEnum.trialExtendSurvey &&
        authCompany?.billing?.couponCodes?.includes(SURVEY_TRIAL_EXTEND) as boolean,
        userId: authUser.id,
      })) {
        setShowSurveyModal(true)
        window.addEventListener('message', handleUnsubscribe)
        return () => {
          window.removeEventListener('message', handleUnsubscribe)
        }
      }
    }
  }, [showZohoSurvey, authCompany?.billing?.couponCodes])

  useEffect(() => {
    if (showZohoSurvey) {
      setShowSurveyModal(showZohoSurvey)
      localStorage.removeItem(surveyLocalStorageKey)
    }
  }, [showZohoSurvey])

  useEffect(() => {
    if (authCompany && authUser) {
      setSurveySrc(`${zohoPopSurveyUrl}?email=${authUser.email}&userId=${authUser.id}&companyId=${authCompany?.id}`)
    }
  }, [authUser, authCompany])

  useEffect(() => {
    if (!isSurveyDone && authCompany?.subscriptionStatus === 'trialing') {
      const zohoPopSurvey = document.querySelectorAll(`[src="${zohoPopSurveyUrl}"]`)
      const zohoIFrameElement: Element & {src?: string} = zohoPopSurvey && zohoPopSurvey[0]
      if (surveySrc !== '' && zohoIFrameElement) {
        zohoIFrameElement.src = `${zohoPopSurveyUrl}?email=${authUser.email}&userId=${authUser.id}&companyId=${authCompany.id}`
      }
    }
  }, [surveySrc])

  return <Modal
    visible={showSurveyModal}
    style={{ top: 20 }}
    closeIcon={<CloseCircleOutlined />}
    onCancel={handleCancel}
    footer={null}
    title={<Button type='primary' onClick={handleRemindMeLater}>{formatMessage({ id: 'app.askMeLater' })}</Button>}
  >
    <div style={{ paddingTop: '25px' }}>
      <iframe
        src={zohoPopSurveyUrl}
        frameBorder='0'
        style={{ height: '650px', width: '100%' }}
        marginWidth={0}
        marginHeight={0}
        scrolling='auto'
        allow='geolocation'>
      </iframe>
    </div>
  </Modal>
}

export default ZohoPopUpSurvey
